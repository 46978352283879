const appConfig = {
  app_version: "Version 1.4.25.02",

  fetch_refresh_time: 30000,

  date_format: "MM/DD/YYYY",
  date_time_format: "MM/DD/YYYY hh:mm A",
  time_format: "hh:mm A",
  dateTimeFormat: "MMM DD, YYYY hh:mm A",

  APP_S3_REGION: process.env.REACT_APP_REGION,
  APP_S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  API_GATEWAY: process.env.REACT_APP_API_GATEWAY,
  APP_COGNITO_REGION: process.env.REACT_APP_REGION,
  APP_COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  APP_IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,

  entityNames: {
    users: "users",
    // addTestProfiles: "addTestProfiles",
    masterData: "masterData",
    registrations: "registrations",
    getMasterList: "getMasterList",
    contentManagement: "contentManagement",
  },

  app_colors: {
    greenLight: "#00A588",
    greenLight1: "#0BA81115",
    greenDark: "#0BA811",
    yellowLight: "#E8B301",
    yellowDark: "#CE8700",
    yellowDark1: "#E8B30115",
    red: "#E80101",
    redLight: "#E8010115",
    blueLight: "#3e69a4",
    blueDark: "#061178",
    greyLight: "#94A3B8",
    greyLight1: "#1E293B",
    greyDark: "#6688b7",
    black: "#000000",
    white: "#FFFFFF",
    whiteCreamy: "#f0f2f5",
  },

  profetion: [
    "Administration",
    "Aerospace & Aviation",
    "Agriculture",
    "Architecture",
    "Automobile",
    "Banking",
    "Beauty & Wellness",
    "Building/Hardware & Home Furnishing",
    "Business & Enterprenuer",
    "Chemical & Pharmaceuticals",
    "C-Level Jobs",
    "Communications & Customer Services",
    "Construction",
    "Counselor",
    "Defence",
    "Designing & Arts",
    "Distributors & Warehousing",
    "Education / Skill development",
    "Electronics & Hardware",
    "Engineering",
    "Event Management",
    "Finance",
    "Gems & Jewellery",
    "Healthcare",
    "Hospitality",
    "Hotel & Restaurants",
    "Human Resources",
    "Information Technology",
    "Insurance",
    "ITES/BPO",
    "Law Enforcement",
    "Management",
    "Manufactring & Production",
    "Marketing",
    "Media",
    "Military",
    "Music",
    "Operations",
    "Packaging",
    "Politics",
    "Public Relations",
    "Real Estate",
    "Research & Analyst",
    "Research and Development",
    "Retail",
    "Sales",
    "Sports",
    "Security",
    "Scintist",
    "Transportation",
    "Trade Department",
    "Theater & Film Industry",
    "Volunteer",
    "Writer",
  ],

  subTitle: {
    Administration: [
      "Account Collector",
      "Administrative Analyst",
      "Administrative Assistant",
      "Administrative Clerk",
      "Administrative Coordinator",
      "Administrative Customer Service",
      "Administrative Director",
      "Administrative Executive Assistant",
      "Administrative Manager",
      "Administrative Specialist",
      "Administrative Supervisior",
      "Administrator",
      "Auditing Clerk",
      "Bookkeeper",
      "Branch Manager",
      "Business Analyst",
      "Business Manager",
      "Chief Executive Officer",
      "Data Entry Operator",
      "Executive Assistant",
      "File Clerk",
      "Human Resources",
      "Office Assistant",
      "Office Clerk",
      "Office Manager",
      "Program Administrator",
      "Program Manager",
      "Quality Control Coordinator",
      "Receptionist",
      "Risk Manager",
      "Secretary",
    ],

    "Aerospace & Aviation": [
      "Aerospace Engineer",
      "Aerospace Materials Specialist",
      "Air Traffic Controller",
      "Aircraft Cleaner",
      "Aircraft Maintenance Technician",
      "Aircraft Refueller",
      "Aircraft Structures Technician",
      "Airport Manager",
      "Airport Security Executive",
      "Avionics and Electronics Technician",
      "Baggage Handler / Cargo Handler",
      "Commercial Pilot",
      "Flight Attendent / Cabin Crew",
      "Flight Instructor",
      "Ground Staff",
      "Pilot",
      "Ramp Service Agent",
      "Reservation and Ticketing Agent",
      "Terminal Operations Manager",
    ],

    Agriculture: [
      "Agricultural Economist",
      "Agricultural Engineer",
      "Agricultural Equipment Technician",
      "Agricultural Salesperson",
      "Agricultural Specialist",
      "Agriculture & Allied fields",
      "Agronomy Manager",
      "Beekeeper",
      "Biological Scientist",
      "Commercial Horticulturalist",
      "Conservation Planner",
      "Crop Farmer",
      "Dietitian",
      "Ecologist",
      "Enviromental Scientist",
      "Farm Equipment Mechanic",
      "Farm Manager",
      "Fisheries",
      "Fishery Scientist",
      "Food technologist",
      "Food Technologist",
      "Gardener",
      "Geographers",
      "Geologists",
      "Green House Fitter",
      "Grower",
      "Horticulture Farmer",
      "Indian Forest Service Officer",
      "Meteorologist",
      "Micro Irrigation Technician",
      "Nutrionists",
      "Oceanographers",
      "Operation Manager",
      "Pesticide Handler",
      "Poultry Farmer ",
      "Pump Operator",
      "Soil and Plant Scientist",
      "Tea Plantation Worker",
      "Tractor Servicing / Operator",
      "Vegetable Farmer",
      "Water Resources Engineer",
      "Water Treatment Special",
      "Zoologists",
    ],

    Architecture: [
      "Architectural Designer",
      "Architectural Drafter",
      "Architectural Historian",
      "Architectural Project Manager.",
      "Architectural Technician",
      "Commercial Architect",
      "Historic Preservation Architect",
      "Industrial Architect",
      "Landscape Architect.",
      "Retrofit Architect",
      "Urban Planner",
    ],
    Automobile: [
      "Auto Body Repair Technician",
      "Auto Designer",
      "Auto Electrician",
      "Auto Engineer",
      "Auto Instructor",
      "Auto Mechanic",
      "Auto Sales Manager",
      "Car Detailer",
      "Car Rental Agent",
      "Car Salesperson",
      "CNC Operator",
      "Lathe Operator",
      "Process Engineer",
      "Quality Testing Engineer",
      "Spare Part Operation Executive",
      "Tire Technician",
      "Tow Truck driver",
      "Vehicle Inspector",
      "Washer",
    ],
    Banking: [
      "Assistant Treasurer",
      "Assistant UnderWriter",
      "Bank Clerk",
      "Bank Officer",
      "Banking Associate",
      "Bankruptcy Specialist",
      "Credit Analyst",
      "Fraud Specialist",
      "Loan Manager",
      "Loan Officer",
      "Personal Banking manager",
      "Portfolio Analyst",
      "Senior Bank Official",
      "Senior Banker",
    ],
    "Beauty & Wellness": [
      "Aromatherapist",
      "Barber",
      "Beauty Blogger",
      "Beauty Consultant",
      "Beauty Expo Planner",
      "Beauty PR",
      "Beauty Sales Rep",
      "Beauty Therapist",
      "Beauty Tutor",
      "Colorist",
      "Cosmetic Wig Maker",
      "Cosmetologist",
      "Esthetician",
      "Eyelash Specialist",
      "Fashion Designer",
      "Hairstylist & Hairdresser",
      "Image Consultant",
      "Makeup Artist",
      "Massage Therapist",
      "Nail Professional",
      "Salon / Spa Owner",
      "Salon Manager",
      "Skincare Specialist",
      "Tanning Consultant",
      "Tatto Artist",
      "Teeth Whitening Specialist",
    ],
    "Building/Hardware & Home Furnishing": [
      "Building carpenter",
      "Building Hardware and home Furnishers",
      "Ceiling Fixer",
      "Civil Engineering technician",
      "Draftsman",
      "Earth Work Excavator",
      "Electrical Wiremen / Technician",
      "Fitter",
      "Interior Designer",
      "Paint Sprayer",
      "Scaffolder",
      "Sheet Metal Worker",
      "Tiler",
      "Turner",
    ],
    "Business & Enterprenuer": [
      "Administrator",
      "CEO",
      "Chairperson",
      "Creative Director",
      "Director ",
      "Director Of Operation",
      "Founder",
      "General Manager",
      "Managing director",
      "Managing partner Or Managing Member",
      "Owner",
      "President",
      "Principal",
      "Proprietor",
      "Technical Director",
    ],
    "Chemical & Pharmaceuticals": [
      "Biomedical Engineer",
      "Chemical & Pharmaceuitical Operator",
      "Chemical Technician",
      "Epidemiologist",
      "Equipment Operator",
      "Industrial Chemical Manufacturing Attendent",
      "Lab Attendent",
      "Laboratory Technician",
      "Manufacturing Assistant",
      "Microbiologist",
      "Principal Scientist",
      "Process Attendent-Chemical Plants",
      "Product Development Scientist",
      "Production and QC technician",
      "Research Scientist",
      "Senior Scientist",
    ],

    "C-Level Jobs": [
      "CCO—Chief Customer Officer",
      "CDO—Chief Data Officer",
      "CEO—Chief Executive Officer",
      "CFO—Chief Financial Officer",
      "CHRO—Chief Human Resources Officer",
      "CIO—Chief Information Officer",
      "CMO—Chief Marketing Officer",
      "COO—Chief Operating Officer",
      "CPO—Chief Product Officer",
      "CTO—Chief Technology Officer",
    ],
    "Communications & Customer Services": [
      "Account Coordinator",
      "Account Representative",
      "Bank Teller",
      "Call Center Agent",
      "Cashier",
      "Client Service Specialist",
      "Client Services Coordinator",
      "Client Services Manager",
      "Concierge",
      "Customer Care Associate",
      "Customer Service",
      "Customer Service Manager",
      "Customer Support",
      "Dersified Telecommunications Services",
      "Entertainment",
      "Front Desk Coordinator",
      "Help Desk Assistant",
      "Interactive Media & Services",
      "Retail Sales Associate",
      "Service Adviser",
      "Technical Support Representative",
      "Technical Support Specialist",
      "Virtual Assistant",
      "Wireless Telecommunication Services",
    ],
    Construction: [
      "Architect",
      "Assistant project Manager",
      "Bar bender",
      "Boilermaker",
      "Building Services Director",
      "Carpenters",
      "Civil Engineer",
      "Construction Assistant",
      "Construction Engineer",
      "Construction Worker",
      "Crane Operators",
      "Electricians",
      "Equipment Operator",
      "Estimator",
      "Field Engineer",
      "Foremen",
      "Inspector",
      "Iron Worker",
      "Mason",
      "Pipefitter",
      "Plumber",
      "Project Manager",
      "Road Worker",
      "Roofer",
      "Safety Manager",
      "Scheduler",
      "Senior Construction Manager",
      "Sheetmetal Worker",
      "Solar Photovoltaic Installer",
      "Structural Engineer",
      "Superintendent",
      "Supervisors",
      "Surveyor",
      "Taper",
      "Vehicle Equipment Cleaner",
      "Welder",
      "Well Driller",
    ],
    Counselor: [
      "Addiction Counselor",
      "Counselor",
      "Couples Counselor",
      "Guidance Counselor",
      "Life Coach",
      "Mental Health Counselor",
      "School Counselor",
      "Social Worker",
      "Speech Pathologist",
      "Therapist",
    ],
    Defence: [
      "Assistant",
      "Assistant Master",
      "Clerk",
      "Combined Defence Service",
      "Constables",
      "Defence Civilians",
      "Draughtsman",
      "Driver",
      "Engineer",
      "Fireman",
      "Forester",
      "Instructor",
      "Junior Assistant",
      "Manager, IT Consultant",
      "Mechanic",
      "Medical Officer",
      "Motor Transport Assistant",
      "Navik",
      "Night gaurd",
      "Officer",
      "Officer Director",
      "Peon ",
      "Private Secretary",
      "Safaikarma Chari",
      "Sanitary Inspector",
      "Steno Typist",
      "Stenographer,Cook, & Multitasking Staff",
      "Tax Collector",
      "Teacher",
      "Technician",
      "Technician Apprentice",
      "Tradesman",
      "Tradesman mate",
      "Translator",
    ],
    "Designing & Arts": [
      "Accessory Designer",
      "Animation Filmaker",
      "Architect",
      "Beautician",
      "Blog Writer",
      "Content Writer",
      "Fashion Designer",
      "Floral Designer",
      "Footwear Designer",
      "Furniture Designer",
      "Gemologist",
      "Graphics Designer",
      "Illustrator",
      "Interior Designer",
      "Jewellery Designer",
      "Landscape Architect",
      "Lifestyle Accessory Designer",
      "Painting",
      "Photographer",
      "Poet Writer",
      "Product Designer",
      "Retail & Exhibition Designer",
      "Sculpture Artist",
      "Sketch Artist",
      "Story Writer",
      "Textile Designer",
      "Toy & Games Designer",
      "Transportation & Automobile Designer",
      "User Experience Designer",
    ],
    "Distributors & Warehousing": [
      "Distribution Center Manager",
      "Forklift Driver / Operator",
      "General Laborer",
      "Inventory Control Manager",
      "Loader",
      "Machine Operator",
      "Material Handler",
      "Merchandise Pickup / Receiving Associate",
      "Receiver",
      "Shipping Specialist",
      "Stocker",
      "Ware House Clerk",
      "Ware House Manager",
      "Ware House Packer",
      "Ware House Supervisor",
      "Ware House Worker",
    ],
    "Education / Skill development": [
      "Assistant Professor",
      "English Teacher",
      "Foreign Language Teacher",
      "Guidance Counselor",
      "Instructional Designer",
      "Lecturers",
      "Librarian",
      "Math Teacher",
      "Mentor",
      "Online ESL Instructor",
      "Physical Education Instructor / Coach",
      "Preschool Teacher",
      "Principal",
      "Professor",
      "School Psychologists",
      "School teacher",
      "Science Teacher",
      "Special Educators",
      "Substitute Teacher",
      "Superintendent",
      "Teacher Aide",
      "Teacher Kindergarten",
      "Teacher Preschool",
      "Teacher Special Education",
      "Teaching Assistant",
      "Test Administrator",
      "Test Scorer",
      "Translator",
      "Tutor / Online Tutor",
      "Vice Principal",
    ],
    "Electronics & Hardware": [
      "Battery Repairer",
      "Cable Jointer",
      "Desktop Support Engineer",
      "Electroplater",
      "Lineman / Wireman",
      "Mechanic- TV / Radio",
      "Miaintenence Engineer",
      "Network Planning / Infrstructure Engineer",
      "Pick and Place Assembly Operator",
      "Power Electrician",
      "Refrigeration & Air Conditioning Mechanic",
      "Server Support Engineer",
      "Set top Box Installer / Service",
      "Switching / Router Engineer",
      "Technical Support Engineer",
      "Test Engineer",
    ],
    Engineering: [
      "Engineering",
      "Engineering Assistant",
      "Engineering Intern",
      "Engineering Maintenance",
      "Engineering Manager",
      "Engineering Program Manager",
      "Engineering Project Manager",
      "Engineering Recuiter",
      "Engineering Supervisior",
      "Engineering Technician",
    ],
    "Event Management": [
      "Event Administrator",
      "Event Coordinator",
      "Event Manager",
      "Event Marketing Manager",
      "Event Planner",
      "Event Sales Executive",
      "Event Sales Manager",
      "Event Staff",
    ],
    Finance: [
      "Finance Administrator",
      "Finance Assistant",
      "Finance Director",
      "Finance Manager",
      "Finance Officer",
      "Financial Accountant",
      "Financial Advisor",
      "Financial Analyst",
      "Financial Controller",
      "Financial System Analyst",
    ],
    "Gems & Jewellery": [
      "Bench Jeweller",
      "Casting Machine Operator",
      "Diamond Assorter and Bagger",
      "Diamond Bruter",
      "Diamond Processing Mawyer",
      "Enameller",
      "Gems and Jewellery Wholesaler",
      "Germologist",
      "Jewellery Appraiser",
      "Jewellery Designer (Manual & Computerized)",
      "Jewellery Merchandiser",
      "Jewellery Setter",
      "Lase Operator",
      "Planner / Maker",
      "Polisher",
      "Quality Assurance Technician / Lab Graders",
      "Repair Technician",
      "Watchmaker",
      "Wax Setter",
    ],
    Healthcare: [
      "Acupuncturist",
      "Athletic Trainer",
      "Audiologist",
      "Ayurvedic Physician",
      "Cardiologist",
      "Cardiothoracic Surgeon",
      "Caregiver",
      "CNA",
      "Cytotechnologist",
      "Dental Hygienist",
      "Dentist",
      "Dermatologist",
      "Dietician Assistant",
      "Doctor",
      "Dresser",
      "Endocrinologist",
      "Forensic Scientist",
      "General Duty Attendent",
      "Geneticist",
      "Gynaecologist",
      "Health Educator",
      "Homeopathy",
      "Massage Therapy",
      "Medical Administrator",
      "Medical Laboratory Tech",
      "Medical Social Worker",
      "Medical Transcriptionist",
      "Microbiologist",
      "Neurologist",
      "Nurse",
      "Nurse Practitioner",
      "Nutrionist",
      "Oncologist",
      "Opathalmologist",
      "Optometrist",
      "Orthopaedic Surgeon",
      "Pathologist",
      "Personal Trainer",
      "Pharmacist",
      "Pharmacy Assistant",
      "Phlebotomist",
      "Physical Therapist",
      "Physical Therapy Assistant",
      "Physician Doctor",
      "Physiotherapist",
      "Psychiatrists",
      "Pulmonologist",
      "Radiation Therapist",
      "Reiki Practitioner",
      "Telework Nurse / Doctor",
      "Therapist",
      "Travel Nurse",
      "Vaccination Technician",
      "Veterinarian",
    ],
    Hospitality: [
      "Bellhop",
      "Casino Host",
      "Concierge",
      "Cruise Director",
      "Cruise Ship Attendant",
      "Director of Maintenance",
      "Entertainment Specialist",
      "Event Planner",
      "Events Manager",
      "Flight Attendant",
      "Front Desk Associate",
      "Front Desk Manager",
      "Group Sales",
      "Hotel Front Door Greeter",
      "Hotel Manager",
      "Hotel Receptionist",
      "Housekeeper",
      "Lodging Manager",
      "Meeting Planner",
      "Porter",
      "Reservationist",
      "Spa Manager",
      "Travel Agent",
      "Valet",
      "Wedding Coordinator",
    ],
    "Hotel & Restaurants": [
      "Assistant Manager",
      "Baker",
      "Banquet Cook",
      "BarBack",
      "Barista",
      "Bartender",
      "Busser / Bus Person",
      "Butcher",
      "Cake Decorator",
      "Cashier",
      "Dishwasher",
      "Drive Thru Operator",
      "Executive Chef",
      "Fast Food Cook",
      "Food Beverage Manager",
      "Food Blogger",
      "Food Technologist",
      "Food Tester",
      "General Manager",
      "Host / Hostess",
      "Hotel Banquet Service",
      "Hotel Front Desk Agent",
      "Hotel Housekeeper",
      "Hotel Laundry Attendent",
      "Hotel Maintenance",
      "Hotel Night Auditor",
      "Hotel Recepionist",
      "Hotel Security Officer",
      "Kitchen Manager",
      "Line Cook",
      "Pastry Chef",
      "Prep Cook",
      "Runner",
      "Server",
      "Short Order Cook",
      "Sommelier",
      "Sous Chef",
      "Sushi Chef",
    ],
    "Human Resources": [
      "Administrative Assistant",
      "Chief HR officer",
      "Compensation Advisor",
      "Compenstaion & Benefits Manager",
      "Employment Manager",
      "Excutive Recuiter",
      "HR Analytics Specialists",
      "Human Resources Assistant",
      "Human Resources Consultant (HR Consultant)",
      "Human Resources Director",
      "Human Resources Generalist",
      "Human Resources Manager ",
      "Human Resources Specialist",
      "Human Resources Systems Administrator",
      "International HR Manager",
      "International Human Resources Associate",
      "Labor Relations Manager",
      "Talent Acquisition Coordinator",
      "Training & Development Manager",
    ],
    "Information Technology": [
      "App Developer",
      "Application Engineers",
      "Application Maintenance Engineer",
      "Application Security Administrator",
      "Artificial Intelligence Security Specialist",
      "Associate Analytics",
      "Back-End Developer",
      "Big Data Engineer / Architect",
      "Business Intelligence Specialist / Analyst",
      "Business Systems Analyst",
      "Cloud / Software Applications Engineer",
      "Cloud / Software Architect",
      "Cloud / Software Developer",
      "Cloud Computing Analyst",
      "Cloud Security Specialist",
      "Cloud System Administrator",
      "Cloud System Engineer",
      "Computer Programmer.",
      "Computer Systems Analysts",
      "Computer Systems Manager",
      "Cryptographer",
      "Customer Service Representative",
      "Cyber Intelligence Specialist",
      "Cybersecurity Hardware Engineer",
      "Data Analyst",
      "Data Analytics Developer",
      "Data Architect",
      "Data Modeling Analyst",
      "Data Privacy Officer",
      "Data Quality Manager",
      "Data Scientist",
      "Data Warehouse Manager",
      "Data Warehouse Programming Specialist",
      "Database Administrator",
      "Database Analyst",
      "DevOps Engineer",
      "Digital Forensics Analyst",
      "DTP Operator",
      "Ethical Hacker",
      "Front-End Developer",
      "Full-Stack Developer",
      "Game Designer",
      "Hardware Engineer",
      "Help Desk Support Specialist",
      "Help Desk Technician",
      "Information Assurance Analyst",
      "Information Security Analysts",
      "Intelligence Specialist",
      "IT Coordinator",
      "IT Director",
      "IT Project Manager",
      "IT Recovery Specialist",
      "IT Security Engineer",
      "IT Support Specialist",
      "IT Technician",
      "Java Developer",
      "Multi Media Developer",
      "Network Administrator",
      "Network Architect",
      "Networking Engineer",
      "Platform Engineer",
      "Portfolio Manager",
      "Product Developer",
      "Product Manager",
      "Program Manager",
      "Quality Assurance Tester",
      "Release Manager",
      "Reliability Engineer",
      "Security Systems Administrator",
      "Service Desk Analyst",
      "Software Engineer",
      "Software Engineer Programmer",
      "Software Quality Assurance Analyst",
      "SQL Database Administrator",
      "Support Specialist",
      "System Administrator (also known as sysadmin)",
      "System Architect",
      "System Operator",
      "Systems Analyst",
      "Technical Product Manager",
      "UI (user interface) Designer",
      "User Experience Designer",
      "UX (user experience) Designer",
      "Web Designing",
      "Web Developer",
      "Wireless Network Engineer",
    ],
    Insurance: [
      "Actuary",
      "Acturial Manager",
      "Appraiser",
      "Auditor",
      "Benefits Adviser",
      "Claims Adjuster",
      "Claims Analyst",
      "Claims Assistant",
      "Claims Examiner",
      "Claims Investigator",
      "Claims Processor",
      "Claims Representative",
      "Insurance Agent",
      "Insurance Broker",
      "Insurance Coordinator",
      "Insurance Field Inspector",
      "Insurance Producer",
      "Insurance Specialist",
      "Insurance Underwriter",
      "Insurance Verification Specialist",
      "Loss Control Consultant",
    ],
    "ITES/BPO": [
      "Associate-Medical Transcription",
      "Customer Support on Email & Chat",
      "Customer Support-On Phone(English Language)",
      "Customer Support-on Phone(Regional Language)",
      "Data Enrty Operator",
      "Digital Marketer",
      "DTP Publisher",
      "Language Translator",
      "Operating Systems Engineer",
      "SEO Executive",
      "SMO Executive",
      "Software Installation Engineer",
      "Technical Support- Voice or non-voice",
    ],
    "Law Enforcement": [
      "Associate Attorney",
      "Civil Rights Advocate",
      "Consumer Advocate",
      "Corporate Legal Counsel",
      "Judge / Mediator",
      "Judicial Clerk",
      "Juvenile Justice Specialist",
      "Law Clerk",
      "Law Enforcement Officer",
      "Lawyer",
      "Legal Secretary",
      "Litigation Attorney",
      "Paralegal",
      "Private Practice",
      "Senior Associate Attorney",
      "Staff Attorney",
    ],
    Management: [
      "CM Proffesional Practice",
      "Contract Administration",
      "Cost Management",
      "Management Account Assistant",
      "Management Accountant",
      "Management Analyst",
      "Management Assistant",
      "Management Consultant",
      "Management Director",
      "Management Information Analyst",
      "Management Program Analyst",
      "Management Trainee",
      "Manager Business Development",
      "Manager Financial Planning Analyst",
      "Manager Human  Resources",
      "Manager Operator",
      "Manager Quality Assurance",
      "Manager Regulator Affairs",
      "Manager Training",
      "Project Management Planning",
      "Quality Manager",
      "Safety Manager",
      "Time manager",
    ],
    "Manufactring & Production": [
      "Assembler",
      "Assembly Assistant",
      "Director Of Manufacturing",
      "Fabricator",
      "Floor Assembler",
      "Line Production",
      "Line Worker",
      "Manufacturing Associate",
      "Manufacturing Engineer",
      "Manufacturing Manager",
      "Manufacturing Operator",
      "Manufacturing Supervisor",
      "Manufacturing Technician",
      "Producers",
      "Production Associate",
      "Production Operator",
      "Production Worker",
      "Senior Manufacturing Engineer",
    ],
    Marketing: [
      "Advertising Sales Agent",
      "Brand Ambassador",
      "Brand Manager",
      "Brand Strategist",
      "Campaign Manager",
      "Chief Marketing Officer",
      "Communications Manager",
      "Content Marketing Specialist",
      "Content Specialist",
      "Copy Writer",
      "Creative Director",
      "Digital Marketing Manager",
      "Digital Media Manager",
      "Director of Marketing",
      "Graphic Designer",
      "Instagram Brand ambassador",
      "Market Research Analyst",
      "Marketing Assistant",
      "Marketing Associate",
      "Marketing Consultant",
      "Marketing Coordinator",
      "Marketing Director",
      "Marketing Manager",
      "Marketing Specialist",
      "Online Brand Ambassador",
      "Product Marketing Manager",
      "Project Manager",
      "Promotions Manager",
      "Public Relations Specialist",
      "SEO Specialist",
      "Social Media Assistant",
      "Social Media Coordinator",
      "Social Media Director",
      "Social Media Manager",
      "Social MediaSpecialist",
      "VP Marketing",
    ],
    Media: [
      "Communications Director",
      "Editor of Political Journal",
      "Journalist / Author",
      "Political Commentator",
    ],
    Military: [
      "Air Force Housing Specialist",
      "Airforce Career Manager",
      "Airforce Clinical Laboratory Scientist",
      "Airforce Pilot",
      "Airforce Recruiter",
      "Army Biomedical Equipment Specialist",
      "Army Health Unit Coordinator",
      "Army IT Professional",
      "Army Lawyer",
      "Army Linguist",
      "Army Officer",
      "Army Psychiatrist",
      "Marine Corps Infantry Unit Leader",
      "Military Police Officer",
      "National Guard Machinist",
      "Navy Administrative Clerk",
      "Navy Driver",
      "Navy Operations Specialist",
      "Navy Physician",
      "Navy technical Security Threat Intelligence Officer",
      "Tactical Aircraft Maintenance Specialist",
      "Veteran Rehabilitation Specialist",
    ],
    Music: [
      "Artist Manager",
      "Audio or Recording Engineer",
      "Booking Agent",
      "Composer",
      "Electronics Technician",
      "Event Organiser",
      "Music Arranger",
      "Music Director",
      "Music Journalist",
      "Music Producer",
      "Music Publicist",
      "Music Teacher",
      "Musician",
      "Singer",
      "Sound Technician",
      "Tour Manager",
      "Video Engineer",
      "Voice-Over artist",
    ],
    Operations: [
      "Chief Operations Officer",
      "Continuous Improvement Lead",
      "Distribution Supervisor",
      "Logistics Coordinator",
      "Operations Assistant",
      "Operations Manager",
      "Scrum Master",
      "Supply Chain Coordinator",
      "Supply Chain Specialist",
      "Warehouse Supervisor",
    ],
    Packaging: [
      "Assembly Technician",
      "Loader",
      "Packaging Engineer",
      "Packaging Operator",
      "Packer",
      "Product Designer",
      "Product Tester",
      "Quality Assurance Manager",
      "Shipping Coordinator",
      "Stocker",
      "Warehouse Worker",
    ],
    Politics: [
      "Activist",
      "Campaign Manager",
      "Elected or Appointed Positions at Local/State/National level",
      "Legislative or Congressional Aide",
      "Lobbyist",
      "Pollster",
      "Press Secretary",
      "Public Opinion Analyst",
      "Special Interest Advocacy",
      "Speech Writer",
    ],
    "Public Relations": [
      "Associate Public Relation Director",
      "Brand Spokesperson",
      "Communications Coordinator",
      "Content Writer",
      "Copy Writer",
      "Corporate Communications Director",
      "Development Director",
      "Development Specialist",
      "Director of Communications",
      "Director of Community Relations",
      "Director of Consumer PR",
      "Event Coordinator",
      "Event Manager",
      "Event Planner",
      "Financial Consultant",
      "Freelance PR Consultant",
      "Fundraising Coordinator",
      "Fundraising Manager",
      "Head of Brand PR",
      "Head Of PR",
      "Head of Public Affairs",
      "Internal Communications Director",
      "Investor Relations Specialist",
      "Marketing and Advertising Spokesperson",
      "Marketing Proposal Manager",
      "Media Coordinator",
      "PR Administrative Assistant",
      "PR Agent",
      "PR and Community Relation Speciality",
      "PR Consultant",
      "PR Contarctor",
      "PR Coordinator",
      "PR Event Coordinator",
      "PR Intern & Trainee",
      "PR Manager",
      "PR Senior Account Executive",
      "Public Relation & Media Relation Executive",
      "Public Relation Assistant",
      "Public Relation Director",
      "Public Relation Officer",
      "Public Relation Specialist",
      "publicist",
      "Research Director",
      "Senior Director Global Public Relations",
      "Senior Director Public Relations & Social Media",
      "Strategic Communications Specialist",
      "Vice President of Media Relations",
      "VP Of PR",
      "VP Of PR And Communication",
    ],
    "Real Estate": [
      "Commercial Property Manager",
      "Compliance Office",
      "Escrow Officer",
      "Forecloser Specialist",
      "Head Of Marketing",
      "Home Inspector",
      "Lease Administrator",
      "Leasing Agent & Consultant",
      "Leasing Manager",
      "Mortgage Broker",
      "Mortgage Processor",
      "Property Developer",
      "Property Manager",
      "Real Estate Agent",
      "Real Estate Appraiser",
      "Real Estate Associate",
      "Real Estate Attorney",
      "Real Estate Manager",
      "Title Examiner",
    ],
    "Research & Analyst": [
      "Biostatistician",
      "Business Analyst",
      "Data Analyst",
      "Financial Analyst",
      "Market Researcher",
      "Medical Researcher",
      "Research Assistant",
      "Researcher",
      "Title Analyst",
      "Title Researcher",
    ],
    "Research and Developmen": [
      "Consumer Researcher",
      "R&D Assistant",
      "R&D Chemist",
      "R&D Director",
      "R&D Instrumentation Technician",
      "R&D Reagents Engineer / Project Manager",
      "R&D Reagents Technician",
      "R&D Scientist",
      "R&D Specialist",
    ],
    Retail: [
      "Advertising & Marketing Manager",
      "Buyer",
      "Cashier",
      "Cleaning & Maintenance",
      "Customer Service Representative",
      "Human Resources",
      "Inventory Manager",
      "Logistics",
      "Loss Prevention Manager",
      "Retail Warehouse Worker",
      "Sales Associate",
      "Security Guard",
      "Social Media manager",
      "Stocker",
      "Store Manager",
      "Team Leader & Floor Manager",
      "Visual Merchandiser",
    ],
    Sales: [
      "Account Executive",
      "Account Manager",
      "Area Sales Manager",
      "B2B Sales Specialist",
      "Cashier",
      "Collection Agent",
      "Direct Salesperson",
      "Director of Inside Sales",
      "Market Development Manager",
      "Merchandising Associate",
      "Outside Sales Manager",
      "Real Estate Broker",
      "Retail Worker",
      "Sales Analyst",
      "Sales Associate",
      "Sales Engineer",
      "Sales Manager",
      "Sales Representative",
      "Store Manager",
      "Telemarketer",
    ],
    Scintist: [
      "Astronomer",
      "Atmospheric Scientist",
      "Biologist",
      "Chemist",
      "Conservation Scientist",
      "Geologist",
      "Molecular Scientist",
      "Physicist",
      "Political Scientist",
      "Sociologist",
    ],
    Security: [
      "Patrol officer",
      "Screener",
      "Security Analyst",
      "Security Consultant",
      "Security Engineer",
      "Security Escort",
      "Security Guard",
      "Security Manager",
      "Security Officer",
      "Security Specialist",
      "Surveillance Officer",
    ],
    Sports: [
      "Badminton Player",
      "Baseball Player",
      "Basketball Player",
      "Boxing Player",
      "Cricket Player",
      "Football Player",
      "Golf Player",
      "Hockey Player",
      "Kabbadi Player",
      "Table Tennis Player",
      "Volleyball Player",
    ],
    "Theater & Film Industry": [
      "Actor",
      "Agent",
      "Animator",
      "Casting Director",
      "Choreographer",
      "Company Manager",
      "Concept Artist",
      "Costume Designer",
      "Director Of Photography",
      "Drama Teacher",
      "Film Editor",
      "Front-of-House Manager",
      "Hair and Makeup Artist",
      "Lighting Technician",
      "Location Manager",
      "Marketer",
      "Music Producer",
      "Performer",
      "Play Writing & Film Script Writer",
      "Producer",
      "Programme Researcher",
      "Sound Technician",
      "Special Effects Technician",
      "Stage Crew",
      "Theater & Film Director",
      "Theatre Designing",
      "Usher",
      "VFX Artist",
    ],
    "Trade Department": [
      "Commodity Manager",
      "Equity Analyst",
      "Equity Partner",
      "Finance Coordinator",
      "Financial Consultant",
      "Financial Modeler",
      "Foreign Exchange Trader",
      "Investment Consultant",
      "Marketing Associate",
      "Portfolio Analyst",
      "Portfolio Manager",
      "Risk Analyst",
      "Risk Manager",
      "Trade Assistant",
      "Trader",
    ],
    Transportation: [
      "Aircraft Mechanic",
      "Bus Driver",
      "Chauffeur",
      "Courier",
      "Dispatcher",
      "Distribution Manager",
      "Expeditor",
      "Forklift Operator",
      "Logistics Analyst",
      "Pilot",
      "Shipping And Receiving Clerk",
      "Supply Chain Specialist",
      "Traffic Controller",
      "Transportation Coordinator",
      "Transportation Engineer",
      "Truck Driver",
    ],

    Volunteer: [
      "Animal Shelter Board Member",
      "Animal Shelter Volunteer",
      "Church Volunteer",
      "Community Food Project Worker",
      "Community Volunteer Jobs",
      "Conservation Volunteer",
      "Emergency Relief Worker",
      "Food Kitchen Worker",
      "Habitat for Humanity Builder",
      "Homeless Shelter Worker",
      "Hospital Volunteer",
      "Meals on Wheels Driver",
      "Office Volunteer",
      "Red Cross Volunteer",
      "School Volunteer",
      "Sports Volunteer",
      "Suicide Hotline Volunteer",
      "Women’s Shelter Jobs",
      "Youth Volunteer",
    ],
    Writer: [
      "Columnist",
      "Communications Director",
      "Content Creator",
      "Content Strategist",
      "Copy Editor",
      "Copy Writer",
      "Editor / Proofreader",
      "Film Critic",
      "Ghost Writer",
      "Grant Writer",
      "Journalist",
      "Proposal Writer",
      "Public Relations Specialist",
      "Screen Writer",
      "Social Media Specialist",
      "Speech Writer",
      "Technical Writer",
      "Translator",
      "Travel Writer",
      "Video Game Writer",
    ],
  },
};
export default appConfig;
