import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Col, Card, Row, Button, Form, Input, notification } from "antd";
import { Amplify, Auth } from "aws-amplify";
import AwsConfig from "./config/AwsConfigs";
import { getDetails } from "./api/Apis";
import appConfig from "./config/AppConfig";
import logoo from "./Images/logoo.png";
import Loading from "./Components/Loading";
Amplify.configure(AwsConfig);

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const onFinish = async (values) => {
    let lowerEmail = values.EmailAddress.toLowerCase();
    try {
      setLoading(true);
      const user = await Auth.signIn(lowerEmail, values.Password);
      setUser(user);
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      getLoginDetails(userDetails);
    } catch (err) {
      console.log("Login Error:", err);
      if (err.code === "UserNotConfirmedException") {
        notification["error"]({
          message: "Registered user not confirmed.!",
        });
        setLoading(false);
      } else if (err.code === "PasswordResetRequiredException") {
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      } else if (err.code === "NotAuthorizedException") {
        setLoading(false);
        notification["error"]({
          message: "Wrong email or password. Please try again.",
        });
      } else if (err.code === "UserNotFoundException") {
        setLoading(false);
        notification["error"]({
          message: "User not found.!",
        });
      } else {
        notification["error"]({
          message: "Something went wrong.!",
        });
        setLoading(false);
      }
    }
  };

  const getLoginDetails = (userDetails) => {
    setLoading(true);
    getDetails(appConfig["entityNames"]["users"], userDetails.userid)
      .then((result) => {
        if (result.success) {
          // setLoading(false);
          let data = result.data;
          localStorage.setItem("userLogin", true);
          localStorage.setItem("userDetails", JSON.stringify(data));
          if (data.userProfile === "A") {
            navigate("/dashboard");
          } else {
            navigate("/");
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("User details fetch : ", error);
        // setLoading(false);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const onFinishFailed = () => {
    notification["error"]({
      message: "Submit failed!",
    });
  };

  return (
    <Col span={24}>
      <Row justify={"center"}>
        <Col>
          <Card
            style={{
              marginTop: "30vh",
              boxShadow: "  0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              width: "400px",
            }}
          >
            <Row justify={"center"}>
              <Col>
                <img
                  src={logoo}
                  style={{ width: "230px", marginBottom: "20px" }}
                />
              </Col>
            </Row>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="login-form"
              style={{ marginTop: "20px" }}
            >
              <Form.Item
                name="EmailAddress"
                label="Email Address"
                className="login-psrd"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                ]}
              >
                <Input
                  placeholder="Email Address"
                  required
                  className="login-mail"
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                className="login-psrd"
                rules={[
                  {
                    required: true,
                    message: "Please input Password",
                  },
                ]}
              >
                <Input.Password placeholder="Password" className="login-mail" />
              </Form.Item>
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#343ca4",
                  color: "#fff",
                  borderRadius: "5px",
                  width: "100%",
                  height: "40px",
                }}
                loading={loading}
              >
                Login
              </Button>
            </Form>
            <h4
              style={{ color: "gray", textAlign: "center", marginTop: "15px" }}
            >
              {appConfig.app_version}
            </h4>
          </Card>
        </Col>
      </Row>

      <Loading enableLoading={loading} />
    </Col>
  );
};
export default Login;
