import React, { useState, useEffect } from "react";
import { Col, Table, notification } from "antd";
import Header from "./Header";
import { getDetails, list } from "../api/Apis";
import appConfig from "../config/AppConfig";
export default function Community(props) {
  const [testProfiles, setTestProfiles] = useState([]);
  const columns = [
    {
      title: "First Nmae",
      dataIndex: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Currently live",
      dataIndex: "currentlyLive",
    },
    {
      title: "Job Profession",
      dataIndex: "profession",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Employer Name",
      dataIndex: "employerName",
    },
    {
      title: "Start Year",
      dataIndex: "startYear",
    },
    {
      title: "End Year",
      dataIndex: "endYear",
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  useEffect(() => {
    document.title = "Visakha Valley School - Alumni Association";
    getTestProfiles();
  }, []);

  const getTestProfiles = async () => {
    // let id = props?.match?.params?.id;
    try {
      list(appConfig["entityNames"]["registrations"]).then((result) => {
        if (result.success) {
          setTestProfiles(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };
  return (
    <div>
      <Col span={24}>
        <Header />
      </Col>
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <h1>COMING SOON</h1>
      </div>
       {/* <Col span={24} style={{ marginTop: "30px" }}>
        <Table
          columns={columns}
          dataSource={testProfiles}
          onChange={onChange}
        />
        ;
      </Col> */}
    </div>
  );
}
