import React, { useState } from "react";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";
import logoo from "../Images/logoo.png";
import "../Components/Directory.css";

export default function Header() {
  const [show, setShow] = useState(false);

  const Menusection = () => {
    return (
      <div className="menu-locationgrp">
        <Row style={{ justifyContent: "right" }}>
          <Col>
            {/* <Button
    style={{
      fontSize: "20px",
      border: "0px",
      height: "40px",
      backgroundColor: "#ffffff",
    }}
  >
    <Link to="/community">
      <span style={{ color: "#000" }}>Community </span>
    </Link>{" "}
  </Button> */}

            <Link to="/">
              <Button
                style={{
                  border: " 0px",
                  boxShadow: "0px 0px 0px 0px",
                  fontSize: "20px",
                  color:
                    window.location.pathname === "/" ? "#343CA4" : "#000000",
                }}
              >
                HOME
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/events">
              <Button
                style={{
                  border: " 0px",
                  boxShadow: "0px 0px 0px 0px",
                  fontSize: "20px",
                  color:
                    window.location.pathname.includes("eventDetail") ||
                    window.location.pathname.includes("projectDetail") ||
                    window.location.pathname === "/events"
                      ? "#343CA4"
                      : "#000000",
                }}
              >
                EVENTS & PROJECTS
              </Button>
            </Link>{" "}
          </Col>
          <Col>
            <Link to="/directory">
              <Button
                style={{
                  border: " 0px",
                  boxShadow: "0px 0px 0px 0px",
                  fontSize: "20px",
                  color:
                    window.location.pathname === "/directory"
                      ? "#343CA4"
                      : "#000000",
                }}
              >
                DIRECTORY
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/gallery">
              <Button
                style={{
                  border: " 0px",
                  boxShadow: "0px 0px 0px 0px",
                  fontSize: "20px",
                  color:
                    window.location.pathname === "/gallery"
                      ? "#343CA4"
                      : "#000000",
                }}
              >
                GALLERY
              </Button>
            </Link>
          </Col>
          <Col>
            <Button
              style={{
                fontSize: "18px",
                border: "0px",
                backgroundColor: "#343CA4",
                color: "#fff",
                height: "40px",
                borderRadius: "5px",
                marginRight: "30px",
              }}
            >
              <Link to="/registration">
                <span>JOIN ASSOCIATION </span>
              </Link>
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ position: "fixed", zIndex: "100", width: "100%" }}>
      {/* <Col
        span={24}
        style={{ backgroundColor: "#343CA4", color: "#fff", height: "40px" }}
      >
        <Row justify="end">
          <span style={{ marginRight: "2%", marginTop: "10px" }}>
            <Link to="/login">
              <span style={{ marginRight: "0px", color: "#fff" }}>LOGIN</span>
            </Link>
          </span>
        </Row>
      </Col> */}
      <Col span={24} style={{ backgroundColor: "#fff" }}>
        <Row justify={"space-between"}>
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Link to="/" className="link-logo">
              <img
                style={{
                  width: "250px",
                  marginLeft: "30px",
                  padding: "20px 0px",
                }}
                alt="logo"
                src={logoo}
              />
            </Link>

            <button className="btn-tgle" onClick={() => setShow(!show)}>
              &equiv;
            </button>
          </Col>

          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
            <div className="Desktop-grp">
              <Menusection />
            </div>
            <div className="mobile-grp">{show ? <Menusection /> : ""}</div>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
