import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Components/Admin/Dashboard";
import HomePage from "./Components/HomePage";
import Registration from "./Components/Registration";
import Directory from "./Components/Directory";
import Community from "./Components/Community";
import Gallery from "./Components/Gallery";
import Events from "./Components/Events";
import DataManagment from "./Components/Admin/DataManagment";
import ManageDirectory from "./Components/Admin/Directory";
import GalleryComponent from "./Components/GallerCommponent";
import RecordDetail from "./Components/RecordDetail";
import Directory2 from "./Components/Admin/Directory2";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/registration":
        title = "";
        metaDescription = "";
        break;
      case "/directory":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/directory" element={<Directory />} />
      <Route path="/community" element={<Community />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/events" element={<Events />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dataManagment" element={<DataManagment />} />
      <Route path="/manageDirectory" element={<ManageDirectory />} />
      <Route path="/galleryComponent" element={<GalleryComponent />} />
      <Route path="/eventDetail/:id" element={<RecordDetail />} />
      <Route path="/projectDetail/:id" element={<RecordDetail />} />
      <Route path="/directory" element={<Directory2 />} />
    </Routes>
  );
}
export default App;
