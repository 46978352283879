import React, { useState, useEffect } from "react";
import {
  Tabs,
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  notification,
  Table,
  Popconfirm,
  Divider,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { list, deleteRecord, update, add } from "../../api/Apis";
import appConfig from "../../config/AppConfig";
import Loading from "../../Components/Loading";

export default function DataManagment() {
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchRecord, setSearchRecord] = useState("");

  useEffect(() => {
    getMasterList();
  }, []);

  const getMasterList = () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["masterData"]).then((result) => {
        if (result.success) {
          setFilterData(result?.data);
          
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const onFinish = (entityData) => {
    setLoading(true);
    try {
      const data = {
        entityData,
      };
      add(appConfig["entityNames"]["masterData"], data).then((result) => {
        if (result.success) {
          setLoading(false);
          notification["success"]({
            message: "Record created successfully!",
          });
          getMasterList();
        } else {
          setLoading(false);
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["masterData"], id).then(
      (deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          setLoading(false);
          getMasterList();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "uniqueName",
      sorter: (a, b) =>
        a?.uniqueName?.toLowerCase() < b?.uniqueName?.toLowerCase() ? 1 : -1,
      render: (uniqueName) => {
        return <div style={{ textTransform: "capitalize" }}>{uniqueName}</div>;
      },
    },
    {
      width: "60px",
      title: "Action",
      dataIndex: "id",
      render: (id) => {
        return (
          <div>
            <Button className="headerOptionsDelete" style={{ border: "0px" }}>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteItem(id)}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </Button>
          </div>
        );
      },
    },
  ];

  const showTable = (dataList) => {
    return (
      <Table
        columns={columns}
        dataSource={
          searchRecord === ""
            ? dataList
            : dataList?.filter((item) => {
                return item?.uniqueName?.includes(searchRecord?.toLowerCase());
              })
        }
      />
    );
  };

  const items = [
    {
      key: "1",
      label: `City`,
      children: showTable(
        filterData?.filter((item) => {
          return item?.dataType === "currentlyLive";
        })
      ),
    },
    {
      key: "2",
      label: `Job`,
      children: showTable(
        filterData?.filter((item) => {
          return item?.dataType === "jobTitle";
        })
      ),
    },
    {
      key: "3",
      label: `Start Year`,
      children: showTable(
        filterData?.filter((item) => {
          return (
            item?.dataType === "startYear" 
          );
        })
      ),
    },
    {
      key: "4",
      label: `End Year`,
      children: showTable(
        filterData?.filter((item) => {
          return item?.dataType ==="endYear";
        })
      ),
    },
  ];

  return (
    <div style={{ padding: "30px" }}>
      <Col span={24}>
        <h2>Data Management</h2>
        <Form name="basic" onFinish={onFinish} autoComplete="on">
          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col span={4}>
                <Form.Item
                  name="uniqueName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter input!",
                    },
                  ]}
                >
                  <Input placeholder="Enter title" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="dataType"
                  rules={[
                    {
                      required: true,
                      message: "Please select type!",
                    },
                  ]}
                >
                  <Select placeholder="Select type">
                    <Option value="currentlyLive">City</Option>
                    <Option value="jobTitle">Job</Option>
                    <Option value="startYear">StartYear</Option>
                    <Option value="endYear">EndYear</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item>
                  <Button
                    style={{ backgroundColor: "#061178", color: "#fff" }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Col>

      <Divider style={{ marginTop: "0px" }} />

      <Tabs
        type="card"
        destroyInactiveTabPane={true}
        defaultActiveKey={1}
        size="small"
        items={items}
        tabBarExtraContent={{
          right: (
            <Input
              onChange={(e) => setSearchRecord(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Search...."
              suffix={<SearchOutlined className="colltable-search" />}
            />
          ),
        }}
      />

      <Loading enableLoading={loading} />
    </div>
  );
}
