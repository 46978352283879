import React, { useEffect, useState } from "react";
import { Layout, Menu, Row, Col, Dropdown, Avatar, Modal, Card } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  PoweroffOutlined,
  DownOutlined,
  PictureOutlined,
  ReadOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import { Auth } from "aws-amplify";
import Directory from "./Directory";
import DataManagment from "./DataManagment";
import ManageContent from "./ManageContent";
import ManageUpcoming from "./ManageUpcoming";
import Gallery from "./Gallery";
import logo from "../../Images/unnamed.png";
import visakhaValleylogo from "../../Images/visakhaValleylogo.png";
import Directory2 from "./Directory2";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Dashboard", "1", <PieChartOutlined />),
  getItem("Home", "2", <ReadOutlined />),
  getItem("Events & Projects", "3", <DesktopOutlined />),
  getItem("Directory", "4", <TeamOutlined />),
  getItem("New Categories", "5", <ApartmentOutlined />),
  getItem("Gallery", "6", <PictureOutlined />),
  getItem("Settings", "7", <SettingOutlined />),
];

export default function Dashboard() {
  const [show, setShow] = useState("1");
  const [collapsed, setCollapsed] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    document.title = "Visakha Valley School - Alumni Association";
    // checkAuth();
  }, []);

  const checkAuth = async () => {
    await Auth.currentSession()
      .then((response) => {
        token = response.idToken.jwtToken;
      })
      .catch((error) => {
        console.log(error);
        window.location.assign("/login");
      });
  };

  const submitMenu = (e) => {
    setShow(e.key);
  };

  const onHandleLogout = () => {
    Modal.confirm({
      title: " Are You Sure...?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => logout(),
    });
  };

  const logout = async () => {
    // setLoading(true);
    try {
      await Auth.signOut()
        .then((res) => {
          // setLoading(false);
          localStorage.setItem("userDetails", JSON.stringify(""));
          localStorage.removeItem("userLogin");
          localStorage.removeItem("userLoginTime");
          this.props.context.setUserDetails("");
          this.props.context.setUser("");
          this.props.context.setUserLogin(false);
          window.location.replace("/");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          // setLoading(false);
          window.location.replace("/");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const menu = (
    <Menu style={{ marginTop: "-5px" }}>
      <Menu.Item
        key="4"
        icon={<PoweroffOutlined />}
        onClick={onHandleLogout}
        style={{
          color: "red",
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  const showWelcomeScreen = () => {
    return (
      <Col>
        <Row justify={"center"}>
          <h1
            style={{ textAlign: "center", fontSize: "32px", marginTop: "25%" }}
          >
            Welcome to Visakha Valley School
          </h1>
        </Row>
      </Col>
    );
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        style={{ backgroundColor: "#343CA4" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {collapsed ? (
          <div className="logo">
            <img
              src={logo}
              style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            />
          </div>
        ) : (
          <div className="logo">
            <img
              src={visakhaValleylogo}
              style={{ width: "95%", margin: "5px" }}
            />
          </div>
        )}
        <Menu
          items={items}
          onClick={submitMenu}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ backgroundColor: "#343CA4" }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ backgroundColor: "#ffffff" }}>
          <Row>
            <Col span={24}>
              <div>
                <Row justify={"end"}>
                  <h4 style={{ color: "#000" }} className="header-tage">
                    <Dropdown overlay={menu} placement="bottom">
                      <div className="header-dropdown">
                        {userDetails?.firstName !== undefined &&
                        userDetails?.firstName !== null &&
                        userDetails?.firstName !== "" ? (
                          <Avatar
                            size="medium"
                            style={{
                              backgroundColor: "#343CA4",
                              marginRight: "5px",
                              marginBottom: "5px",
                            }}
                          >
                            {userDetails?.firstName.charAt(0)}
                          </Avatar>
                        ) : (
                          <Avatar size="medium" icon={<UserOutlined />} />
                        )}

                        <span style={{ color: "#000" }} className="header-name">
                          {userDetails?.firstName + " " + userDetails?.lastName}{" "}
                        </span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </h4>
                </Row>
              </div>
            </Col>
          </Row>
          <br />
        </Header>
        <Content>
          <div>
            {show === "1" ? (
              <>{showWelcomeScreen()}</>
            ) : show === "2" ? (
              <ManageContent />
            ) : show === "3" ? (
              <ManageUpcoming />
            ) : show === "4" ? (
              <Directory />
            ) :show==="5"?(
              <Directory2/>
            ):
            
            
            show === "6" ? (
              <Gallery />
            ) : show === "7" ? (
              <DataManagment />
            ) : (
              <>{showWelcomeScreen()}</>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
