import { Row, Col, Drawer } from "antd";
import logoo from "../Images/unnamed.png";
import "../Components/Directory.css"
const Loading = (props) => {
  const { enableLoading } = props;
  return (
    <Drawer
    className="background-transparent"
    // style={{ background: "#000",opacity:"60%"}}
      visible={enableLoading}
      closable={false}
      placement="top"
      width="100%"
      height="100%"
      size="large"
    >
      <div
        style={{
          width: "240px",
          height: "240px",
          background: "#00000073",
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
        }}
      >
        <Row justify={"center"}>
          <Col>
            <img
              src={logoo}
              style={{
                width: "220px",
                marginBottom: "20px",
                marginTop: "30px",
              }}
            />
          </Col>
        </Row>
        <h1
          style={{
            textAlign: "center",
            color: "#fff",
          }}
        >
          Please wait..
        </h1>
      </div>
    </Drawer>
  );
};
export default Loading;
