import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Button,
  Input,
  Popover,
  Checkbox,
  Card,
  Segmented,
  notification,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import moment from "moment";
import appConfig from "../../config/AppConfig";
import { update, list, deleteRecord } from "../../api/Apis";
import Loading from "../../Components/Loading";
import "../Directory.css";

export default function Directory() {
  const [loading, setLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [registeredRecords, setRegisteredRecords] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedColumnsList, setSelectedColumnsList] = useState([
    "First Name",
    "Last Name",
    "Phone",
    "Email",
    "Location",
    "Profession",
    "Job Title",
    "Status",
    "Actions",
  ]);
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: (a, b) =>
        a?.firstName?.toLowerCase() < b?.firstName?.toLowerCase() ? 1 : -1,
      render: (firstName, record) => {
        return <div style={{ textTransform: "capitalize" }}>{firstName}</div>;
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: (a, b) =>
        a?.lastName?.toLowerCase() < b?.lastName?.toLowerCase() ? 1 : -1,
      render: (lastName, record) => {
        return <div style={{ textTransform: "capitalize" }}>{lastName}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) =>
        a?.phone?.toLowerCase() < b?.phone?.toLowerCase() ? 1 : -1,
      render: (phone, record) => {
        return <div style={{ textTransform: "capitalize" }}>{phone}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) =>
        a?.email?.toLowerCase() < b?.email?.toLowerCase() ? 1 : -1,
      render: (email, record) => {
        return <div style={{ textTransform: "lowercase" }}>{email}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "currentlyLive",
      sorter: (a, b) =>
        a?.currentlyLive?.toLowerCase() < b?.currentlyLive?.toLowerCase()
          ? 1
          : -1,
      render: (currentlyLive, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{currentlyLive}</div>
        );
      },
    },
    {
      title: "Profession",
      dataIndex: "profession",
      sorter: (a, b) =>
        a?.profession?.toLowerCase() < b?.profession?.toLowerCase() ? 1 : -1,
      render: (profession, record) => {
        return <div style={{ textTransform: "capitalize" }}>{profession}</div>;
      },
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      sorter: (a, b) =>
        a?.jobTitle?.toLowerCase() < b?.jobTitle?.toLowerCase() ? 1 : -1,
      render: (jobTitle, record) => {
        return <div style={{ textTransform: "capitalize" }}>{jobTitle}</div>;
      },
    },
    {
      title: "Employer Name",
      dataIndex: "employerName",
      sorter: (a, b) =>
        a?.employerName?.toLowerCase() < b?.employerName?.toLowerCase()
          ? 1
          : -1,
      render: (employerName, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{employerName}</div>
        );
      },
    },
    {
      title: "Complete your Xth",
      dataIndex: "didYouComplete",
      sorter: (a, b) =>
        a?.didYouComplete?.toLowerCase() < b?.didYouComplete?.toLowerCase()
          ? 1
          : -1,
      render: (didYouComplete, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>{didYouComplete}</div>
        );
      },
    },
    {
      title: "Year",
      dataIndex: "selectYear",
      sorter: (a, b) =>
        a?.selectYear?.toLowerCase() < b?.selectYear?.toLowerCase() ? 1 : -1,
      render: (selectYear, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {moment(selectYear).format("YYYY")}
          </div>
        );
      },
    },

    {
      title: "Complete your 12th",
      dataIndex: "didYouCompleteInter",
      sorter: (a, b) =>
        a?.didYouCompleteInter?.toLowerCase() <
        b?.didYouCompleteInter?.toLowerCase()
          ? 1
          : -1,
      render: (didYouCompleteInter, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {didYouCompleteInter}
          </div>
        );
      },
    },
    {
      title: "+2 Year",
      dataIndex: "selectYearInter",
      sorter: (a, b) =>
        a?.selectYearInter?.toLowerCase() < b?.selectYearInter?.toLowerCase()
          ? 1
          : -1,
      render: (selectYearInter, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {selectYearInter !== undefined
              ? moment(selectYearInter).format("YYYY")
              : ""}
          </div>
        );
      },
    },

    {
      title: "Start Year",
      dataIndex: "startYear",
      sorter: (a, b) =>
        a?.startYear?.toLowerCase() < b?.startYear?.toLowerCase() ? 1 : -1,

      render: (startYear, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {moment(startYear).format("YYYY")}
          </div>
        );
      },
    },
    {
      title: "End Year",
      dataIndex: "endYear",
      sorter: (a, b) =>
        a?.endYear?.toLowerCase() < b?.endYear?.toLowerCase() ? 1 : -1,
      render: (endYear, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {moment(endYear).format("YYYY")}
          </div>
        );
      },
    },

    {
      title: "Facebook",
      dataIndex: "facebook",
      sorter: (a, b) =>
        a?.facebook?.toLowerCase() < b?.facebook?.toLowerCase() ? 1 : -1,
      render: (facebook, record) => {
        return <div style={{ textTransform: "capitalize" }}>{facebook}</div>;
      },
    },
    {
      title: "LinkedIn",
      dataIndex: "linkedIn",
      sorter: (a, b) =>
        a?.linkedIn?.toLowerCase() < b?.linkedIn?.toLowerCase() ? 1 : -1,
      render: (linkedIn, record) => {
        return <div style={{ textTransform: "capitalize" }}>{linkedIn}</div>;
      },
    },
    {
      title: "Instagram",
      dataIndex: "instagram",
      sorter: (a, b) =>
        a?.instagram?.toLowerCase() < b?.instagram?.toLowerCase() ? 1 : -1,
      render: (instagram, record) => {
        return <div style={{ textTransform: "capitalize" }}>{instagram}</div>;
      },
    },
    {
      title: "Witch House",
      dataIndex: "wichHouse",
      sorter: (a, b) =>
        a?.wichHouse?.toLowerCase() < b?.wichHouse?.toLowerCase() ? 1 : -1,
      render: (wichHouse, record) => {
        return <div style={{ textTransform: "capitalize" }}>{wichHouse}</div>;
      },
    },
    {
      title: "Received Emails",
      dataIndex: "emails",
      render: (emails, record) => {
        return (
          <div style={{ textTransform: "capitalize" }}>
            {record?.emails ? "YES" : "NO"}
          </div>
        );
      },
    },
    {
      width: "120px",
      title: "Status",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <Row gutter={[16, 16]}>
            <Button
              style={{
                color: record?.isActive === false ? "green" : "tomato",
                borderColor: record?.isActive === false ? "green" : "tomato",
                width: "100px",
              }}
              onClick={() =>
                record?.isActive === false
                  ? handleUpdate(record.id, true)
                  : handleUpdate(record.id, false)
              }
            >
              {record?.isActive === false ? "Publish" : "Un Publish"}
            </Button>
          </Row>
        );
      },
    },
    {
      width: "60px",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <Row gutter={[16, 16]}>
            <Button className="headerOptionsDelete" style={{ border: "0px" }}>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteItem(id)}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </Button>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    registerData();
  }, []);

  const registerData = () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["registrations"]).then((result) => {
        if (result.success) {
          setLoading(false);
          setRegisteredRecords(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const handleUpdate = (recordId, recordStatus) => {
    setLoading(true);
    let data = {
      entityData: {
        isActive: recordStatus,
      },
    };
    update(appConfig["entityNames"]["registrations"], recordId, data)
      .then((result) => {
        if (result.success) {
          setLoading(false);
          notification["success"]({
            message: `Status changed successfully!`,
          });
          registerData();
        } else {
          setLoading(false);
          notification["error"]({
            message: "Something went wrong!",
          });
          console.log("Error : ", result.error);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const deleteItem = (id) => {
    setLoading(true);
    deleteRecord(appConfig["entityNames"]["registrations"], id)
      .then((deleteResults) => {
        if (deleteResults.success) {
          notification["success"]({
            message: "Record deleted successfully!",
          });
          setLoading(false);
          registerData();
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        notification["error"]({
          message: "Something went wrong!",
        });
      });
  };

  const searchSortData = (records) => {
    let recordsList = records;
    if (selectedCategory === "All") {
      recordsList = recordsList;
    } else if (selectedCategory === "Published") {
      recordsList = recordsList.filter((i) => i.isActive === true);
    } else {
      recordsList = recordsList.filter((i) => i.isActive === false);
    }

    if (searchItem === "") {
      recordsList = recordsList;
    } else {
      recordsList = recordsList.filter(
        (e) =>
          (e?.firstName + " " + e?.lastName)
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1 ||
          e?.phone?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          (e?.email?.firstName + " " + e?.email?.lastName)
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1 ||
          e?.currentlyLive?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.jobTitle?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          e?.employerName?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.didYouComplete?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.selectYear?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          e?.endYear?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1
      );
    }
    return recordsList;
  };

  const onCheckColumn = (select) => {
    if (selectedColumnsList.includes(select)) {
      let ind = selectedColumnsList.indexOf(select);
      selectedColumnsList.splice(ind, 1);
      setSelectedColumnsList([...selectedColumnsList]);
    } else {
      setSelectedColumnsList([...selectedColumnsList, select]);
    }
  };

  const tableContent = (
    <div style={{ lineHeight: "80%" }}>
      {columns.map((c) => (
        <p>
          {c.title !== undefined && c.title !== "" ? (
            <Checkbox
              className="checkbox-blue"
              checked={selectedColumnsList.includes(c.title)}
              onChange={() => onCheckColumn(c.title)}
            >
              {c.title}
            </Checkbox>
          ) : (
            <></>
          )}
        </p>
      ))}
    </div>
  );

  return (
    <div style={{ padding: "30px" }}>
      <Col span={24} style={{ marginBottom: "20px" }}>
        <Row>
          <Col span={8}>
            <h2 style={{ marginTop: "5px" }}>
              Directory ({registeredRecords.length})
            </h2>
          </Col>

          <Col span={8}>
            <Row justify={"center"}>
              <Segmented
                onChange={(value) => setSelectedCategory(value)}
                options={[
                  {
                    label: "All",
                    value: "All",
                  },
                  {
                    label: "Published",
                    value: "Published",
                  },
                  {
                    label: "Un-Published",
                    value: "Un-Published",
                  },
                ]}
              />
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]} justify="end">
              <Col span={8}>
                <Input
                  onChange={(e) => setSearchItem(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Search...."
                  suffix={<SearchOutlined className="colltable-search" />}
                  className="collapse-btntitle"
                />
              </Col>
              <Col>
                <Card bodyStyle={{ padding: "5px 10px" }}>
                  <Popover
                    placement="left"
                    trigger="hover"
                    content={tableContent}
                    title="Select Table Columns"
                    className="columnbtntitle"
                  >
                    <UnorderedListOutlined className="filteroption" />
                  </Popover>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Table
        columns={columns.filter(
          (c) => selectedColumnsList.includes(c.title) || c.title === undefined
        )}
        dataSource={searchSortData(registeredRecords)}
      />

      <Loading enableLoading={loading} />
    </div>
  );
}
