import React, { useEffect, useState } from "react";
import { Card, Col, Row, Space, Avatar, notification, Button } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  UserOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import "antd/dist/antd.min.css";
import appConfig from "../config/AppConfig";
import { checkUser } from "../api/Apis";
import Loading from "../Components/Loading";
import "../Components/Directory.css";

export default function Cards() {
  const [loading, setLoading] = useState(false);
  const [registeredRecords, setRegisteredRecords] = useState([]);

  useEffect(() => {
    registerData();
  }, []);

  const registerData = () => {
    setLoading(true);
    try {
      checkUser(
        appConfig["entityNames"]["registrations"] + "?isActive=true"
      ).then((result) => {
        setLoading(false);
        if (result.success) {
          setRegisteredRecords(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const handleUrlClick = (url) => {
    window.location.replace(url);
  };

  return (
    <Col span={24}>
      <Card style={{ border: "0px", marginTop: "20px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <span>DIRECTORY</span>
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: "10px" }}>
          <b style={{ fontSize: "25px" }}>Alumni Directory</b>
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: "10px" }}>
          <span>Connect with fellow alumni from your alma mater using our</span>
          <br />
          <span>comprehensive Alumni Directory.</span>
        </Col>
        <Col
          span={24}
          style={{ textAlign: "center", marginTop: "3vw", marginBottom: "3vw" }}
        >
          <Row gutter={[16, 16]}>
            <Carousel
              className="carsole"
              itemPosition="CENTER"
              autoPlaySpeed={3000}
              enableAutoPlay
              enableMouseSwipe
              itemsToShow={
                window.screen.width >= "1025"
                  ? 6
                  : window.screen.width > "992" && window.screen.width <= "1024"
                  ? 2
                  : 1
              }
              itemsToScroll={1}
            >
              {registeredRecords?.slice(0, 10).map((f) => (
                <Col span={24}>
                  <Card
                    hoverable
                    style={{
                      // boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.2)",
                      borderRadius: "5px",
                      margin: "10px 0px",
                    }}
                  >
                    <Col span={24}>
                      <span style={{ color: "#061178" }}>
                        Alumni {f?.endYear}
                      </span>
                    </Col>
                    <Col span={24} style={{ margin: "15px 0px" }}>
                      <Space wrap size={16}>
                        {f?.image !== null ? (
                          <Avatar
                            size={84}
                            icon={
                              <img
                                src={f?.imageUrl}
                                style={{ width: "100%" }}
                              />
                            }
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#fafafa",
                              verticalAlign: "middle",
                              color: "#061178",
                            }}
                            size={84}
                          >
                            <h1
                              style={{
                                fontSize: "48px",
                                marginTop: "-7px",
                                textTransform: "uppercase",
                              }}
                            >
                              {f?.firstName?.charAt(0)}
                            </h1>
                          </Avatar>
                        )}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <b style={{ textTransform: "capitalize" }}>
                        {f?.firstName} {f?.lastName}
                      </b>
                    </Col>

                    <Col span={24}>
                      <span style={{ textTransform: "capitalize" }}>
                        {f?.profession}
                      </span>
                    </Col>
                    <Col span={24}>
                      <span
                        style={{
                          color: "#B0B0B0",
                          textTransform: "capitalize",
                        }}
                      >
                        <EnvironmentOutlined /> {f?.currentlyLive}
                      </span>
                    </Col>
                    <Col span={24}>
                      <Row
                        justify={"center"}
                        gutter={[16, 16]}
                        style={{ marginTop: "15px" }}
                      >
                        {f?.facebook !== "" &&
                        f?.facebook !== null &&
                        f?.facebook !== undefined ? (
                          <Col>
                            <a
                              href={
                                "https://" + f?.facebook.replace("https://", "")
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FacebookOutlined
                                style={{
                                  color: appConfig.app_colors.greyLight,
                                  fontSize: "24px",
                                }}
                              />
                            </a>
                          </Col>
                        ) : (
                          <Col style={{ height: "24px" }}></Col>
                        )}

                        {f?.instagram !== "" &&
                        f?.instagram !== null &&
                        f?.instagram !== undefined ? (
                          <Col>
                            <a
                              href={
                                "https://" +
                                f?.instagram.replace("https://", "")
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InstagramOutlined
                                style={{
                                  color: appConfig.app_colors.greyLight,
                                  fontSize: "24px",
                                }}
                              />
                            </a>
                          </Col>
                        ) : (
                          <Col style={{ height: "24px" }}></Col>
                        )}

                        {f?.linkedIn !== "" &&
                        f?.linkedIn !== null &&
                        f?.linkedIn !== undefined ? (
                          <Col>
                            <a
                              href={
                                "https://" + f?.linkedIn.replace("https://", "")
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkedinOutlined
                                style={{
                                  color: appConfig.app_colors.greyLight,
                                  fontSize: "24px",
                                }}
                              />
                            </a>
                          </Col>
                        ) : (
                          <Col style={{ height: "24px" }}></Col>
                        )}
                      </Row>
                    </Col>
                  </Card>
                </Col>
              ))}
            </Carousel>
          </Row>
          {registeredRecords.length > 9 && (
            <Row justify={"center"}>
              <Button
                style={{
                  border: "1px solid #343CA4",
                  color: "#343CA4",
                  borderRadius: "5px",
                  marginTop: "30px",
                  height: "40px",
                }}
              >
                <Link to="/directory">
                  <span>Full Directory</span>
                </Link>
              </Button>
            </Row>
          )}
        </Col>
      </Card>
      <Loading enableLoading={loading} />
    </Col>
  );
}
