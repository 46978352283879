import { Call, ApiUrl, ParseGeneralResponse, noAuthApiUrl } from "./ApiUtils";

// const queryString = require('query-string');
export function checkUser(entityName, query = {}) {
  let url = noAuthApiUrl(entityName);
  // url = queryString.stringifyUrl({url: url, query: query});
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function getItemDetails(entityName, id) {
  const url = noAuthApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function list(entityName, query = {}) {
  let url = ApiUrl(entityName);

  // url = queryString.stringifyUrl({url: url, query: query});

  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function getDetails(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
// export function getCustomers(entityName,id) {
//     const url = ApiUrl(entityName+'/'+id);
//     return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
// }
export function add(entityName, data) {
  let url = ApiUrl(entityName);

  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function noAuthAdd(entityName, data) {
  let url = noAuthApiUrl(entityName);

  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function update(entityName, id, data) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function deleteRecord(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "delete",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
