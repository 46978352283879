import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Input,
  Popover,
  Checkbox,
  Card,
  Segmented,
  notification,
} from "antd";
import { SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import appConfig from "../../config/AppConfig";
import { update, list, deleteRecord } from "../../api/Apis";
import Loading from "../../Components/Loading";
import "../Directory.css";

export default function Directory2() {
  const [loading, setLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [registeredRecords, setRegisteredRecords] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedColumnsList, setSelectedColumnsList] = useState([
    "Profession",
    "Job Title",
  ]);
  const columns = [
    {
      title: "Profession",
      dataIndex: "profession",
      sorter: (a, b) =>
        a?.profession?.toLowerCase() < b?.profession?.toLowerCase() ? 1 : -1,
      render: (profession, record) => {
        return <div style={{ textTransform: "capitalize" }}>{profession}</div>;
      },
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      sorter: (a, b) =>
        a?.jobTitle?.toLowerCase() < b?.jobTitle?.toLowerCase() ? 1 : -1,
      render: (jobTitle, record) => {
        return <div style={{ textTransform: "capitalize" }}>{jobTitle}</div>;
      },
    },
  ];

  useEffect(() => {
    registerData();
  }, []);

  const registerData = () => {
    setLoading(true);
    try {
      list(appConfig["entityNames"]["registrations"] + "?isAddedNew=true").then(
        (result) => {
          if (result.success) {
            setLoading(false);
            setRegisteredRecords(result.data);
          } else {
            notification["error"]({
              message: "Something went wrong!",
            });
            setLoading(false);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const searchSortData = (records) => {
    let recordsList = records;
    if (selectedCategory === "All") {
      recordsList = recordsList;
    } else if (selectedCategory === "Published") {
      recordsList = recordsList.filter((i) => i.isActive === true);
    } else {
      recordsList = recordsList.filter((i) => i.isActive === false);
    }

    if (searchItem === "") {
      recordsList = recordsList;
    } else {
      recordsList = recordsList.filter(
        (e) =>
          (e?.firstName + " " + e?.lastName)
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1 ||
          e?.phone?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          (e?.email?.firstName + " " + e?.email?.lastName)
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1 ||
          e?.currentlyLive?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.jobTitle?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          e?.employerName?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.didYouComplete?.toLowerCase().indexOf(searchItem.toLowerCase()) >
            -1 ||
          e?.selectYear?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          e?.endYear?.toLowerCase().indexOf(searchItem.toLowerCase()) > -1
      );
    }
    return recordsList;
  };

  const onCheckColumn = (select) => {
    if (selectedColumnsList.includes(select)) {
      let ind = selectedColumnsList.indexOf(select);
      selectedColumnsList.splice(ind, 1);
      setSelectedColumnsList([...selectedColumnsList]);
    } else {
      setSelectedColumnsList([...selectedColumnsList, select]);
    }
  };

  const tableContent = (
    <div style={{ lineHeight: "80%" }}>
      {columns.map((c) => (
        <p>
          {c.title !== undefined && c.title !== "" ? (
            <Checkbox
              className="checkbox-blue"
              checked={selectedColumnsList.includes(c.title)}
              onChange={() => onCheckColumn(c.title)}
            >
              {c.title}
            </Checkbox>
          ) : (
            <></>
          )}
        </p>
      ))}
    </div>
  );

  return (
    <div style={{ padding: "30px" }}>
      <Col span={24} style={{ marginBottom: "20px" }}>
        <Row>
          <Col span={8}>
            <h2 style={{ marginTop: "5px" }}>
              New Categories ({registeredRecords.length})
            </h2>
          </Col>

          <Col span={8}>
            {/* <Row justify={"center"}>
              <Segmented
                onChange={(value) => setSelectedCategory(value)}
                options={[
                  {
                    label: "All",
                    value: "All",
                  },
                  {
                    label: "Published",
                    value: "Published",
                  },
                  {
                    label: "Un-Published",
                    value: "Un-Published",
                  },
                ]}
              />
            </Row> */}
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]} justify="end">
              <Col span={8}>
                <Input
                  onChange={(e) => setSearchItem(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder="Search...."
                  suffix={<SearchOutlined className="colltable-search" />}
                  className="collapse-btntitle"
                />
              </Col>
              {/* <Col>
                <Card bodyStyle={{ padding: "5px 10px" }}>
                  <Popover
                    placement="left"
                    trigger="hover"
                    content={tableContent}
                    title="Select Table Columns"
                    className="columnbtntitle"
                  >
                    <UnorderedListOutlined className="filteroption" />
                  </Popover>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Col>

      <Table
        columns={columns.filter(
          (c) => selectedColumnsList.includes(c.title) || c.title === undefined
        )}
        dataSource={searchSortData(registeredRecords)}
      />

      <Loading enableLoading={loading} />
    </div>
  );
}
