import React, { useEffect, useState, useRef } from "react";
import ReactPhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import "react-phone-input-2/lib/style.css";
import {
  Col,
  Row,
  Card,
  Input,
  Checkbox,
  Button,
  Form,
  DatePicker,
  notification,
  Radio,
  message,
  Upload,
  Select,
  Divider,
  Space,
} from "antd";
import { CheckOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import Header from "./Header";
import { checkUser, noAuthAdd } from "../api/Apis";
import "../Components/Directory.css";
import appConfig from "../config/AppConfig";
import Loading from "./Loading";
import moment from "moment";

const { Dragger } = Upload;

export default function Registration() {
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(false);
  const [profetions, setProfetions] = useState([]);
  const [check, setCheck] = useState(false);
  console.log(check, "checkkk");
  const [profetionMainTitles, setProfetionMainTitles] = useState([]);
  const [profetionTitles, setProfetionTitles] = useState([]);
  const [isAddedNew, setIsAddedNew] = useState(false);
  // console.log(check,"checj");
  const [valueInter, setValueInter] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [associationInfo, setAssociationInfo] = useState([]);
  const [registeredRecords, setRegisteredRecords] = useState([]);
  const [address, setAddress] = useState(null);
  const [currentCity, setCurrentCity] = useState("");

  const [mainProfetions, setMainProfetions] = useState("");

  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const [items, setItems] = useState([]);
  const [names, setNames] = useState("");

  useEffect(() => {
    document.title = "Visakha Valley School - Alumni Association";
    registerData();
    webContentData();
    setProfetionMainTitles(appConfig.profetion);
  }, []);

  const onNameChange = (event) => {
    setName(event.target.value);
    setIsAddedNew(true);
  };

  const onNameChanges = (event) => {
    setNames(event.target.value);
    setIsAddedNew(true);
  };

  const addNewMainTitle = (e) => {
    e.preventDefault();
    const mainTitlesList = [name].concat(profetionMainTitles);
    setProfetionMainTitles(mainTitlesList);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addNewSubTitle = (e) => {
    e.preventDefault();
    const subTitlesList = [names].concat(profetionTitles);
    setProfetionTitles(subTitlesList);
    setNames("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleSelect = (address) => {
    setAddress(address.label);
    let cityValue = "";
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
            setCurrentCity(cityValue);
          }
        }
      })
      .catch((error) => {
        console.error("Error", error);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  const registerData = () => {
    setLoading(true);
    try {
      checkUser(
        appConfig["entityNames"]["registrations"] + "?isActive=true"
      ).then((result) => {
        setLoading(false);
        if (result.success) {
          setRegisteredRecords(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const webContentData = () => {
    setLoading(true);
    try {
      checkUser(appConfig["entityNames"]["contentManagement"]).then(
        (result) => {
          setLoading(false);
          if (result.success) {
            let associationData = [];
            let fetchedData = result.data;
            fetchedData.map((item) => {
              if (item?.dataType === "association") {
                associationData.push(item);
              }
            });
            setAssociationInfo(associationData);
          } else {
            notification["error"]({
              message: "Something went wrong!",
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChanges = (value) => {
    setCheck(value);
  };
  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getBase64 = (file, cb) => {
    setLoading(true);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const onFinish = async (values) => {
    values["isActive"] = false;
    values["selectYear"] = moment(values["selectYear"]).format("YYYY");
    values["startYear"] = moment(values["startYear"]).format("YYYY");
    values["endYear"] = moment(values["endYear"]).format("YYYY");
    values["selectYearInter"] = moment(values["selectYearInter"]).format(
      "YYYY"
    );
    values["currentlyLive"] = currentCity;
    values["address"] = address;
    values["isAddedNew"] = isAddedNew;
    values["emails"] = check;

    let data = {
      entityData: values,
    };
    if (values["image"]) {
      getBase64(values["image"]["file"]["originFileObj"], (result) => {
        data["entityData"]["image"] = result;
        submitRecord(data);
      });
    } else {
      data["entityData"]["image"] = null;
      submitRecord(data);
    }
  };

  const submitRecord = async (data) => {

    setLoading(true);
    await noAuthAdd(appConfig["entityNames"]["registrations"], data)
      .then((result) => {
        if (result.success) {
          notification["success"]({
            message: "Record added successfully!",
          });
          setIsAddedNew(false);
          document.getElementById("basic").reset();
          // window.location.href = "/";
        } else {
          console.log("Error : ", result.error);
          notification["error"]({
            message: "Something went wrong.!",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error : ", err);
        notification["error"]({
          message: "Something went wrong.!",
        });
      });
  };

  return (
    <div>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Header />
      </Col>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="register-box">
          <Row gutter={[15, 0]}>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="registergrp"
            >
              <h4>
                Join us now and start enjoying the benefits of membership!
              </h4>
              <Row gutter={[8, 0]}>
                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content"
                >
                  <h5>
                    As a member of the alumni association, you will have access
                    to a variety of benefits, including:
                  </h5>
                </Col>

                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  <div className="checkmark-grp">
                    <CheckOutlined style={{ color: "red", fontSize: "20px" }} />
                    <h3>Networking Opportunities</h3>
                  </div>
                </Col>

                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  {/* <div className="checkmark-grp">
                    <CheckOutlined style={{ color: "red", fontSize: "20px" }} />

                    <h3>Continuing Education </h3>
                  </div> */}
                </Col>
                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  <div className="checkmark-grp">
                    <CheckOutlined style={{ color: "red", fontSize: "20px" }} />

                    <h3>Pride and Tradition</h3>
                  </div>
                </Col>

                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  <div className="checkmark-grp">
                    <CheckOutlined style={{ color: "red", fontSize: "20px" }} />

                    <h3>Find Old Connections</h3>
                  </div>
                </Col>

                <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  {/* <div className="checkmark-grp">
                    <CheckOutlined style={{ color: "red", fontSize: "20px" }} />

                    <h3>Networking Opportunities</h3>
                  </div> */}
                </Col>

                {/* <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content2"
                >
                  <hr />
                </Col> */}

                {/* <Col
                  xxl={24}
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="registergrp-content1"
                >
                  <p>
                    “Being a member of the alumni website has been a valuable
                    and rewarding experience. The website is user-friendly and
                    offers opportunities to connect with fellow alumni, stay
                    updated with university news, and get involved with events
                    and volunteer opportunities. I highly recommend it to any
                    alumni looking to stay connected and engaged with their
                    university community.”
                  </p>
                </Col> */}

                {/* {registeredRecords?.slice(0, 10)?.map((student) => (
                  <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="registergrp-content2"
                  >
                    <Row>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <b style={{ textTransform: "capitalize" }}>
                          {student?.firstName + " " + student?.lastName}
                        </b>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <a>
                          <b style={{ color: "blue" }}>
                            Alumni {student?.startYear + "-" + student?.endYear}
                          </b>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                ))} */}

                {/* {associationInfo.map((association, index) => (
                  <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="registergrp-content2"
                  >
                    <Row>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <b style={{ textTransform: "capitalize" }}>
                          {association?.title}
                        </b>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <p>{association?.description}</p>
                      </Col>
                    </Row>
                  </Col>
                ))} */}
              </Row>
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="registergrp"
            >
              <Card style={{ boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete="on"
                  ref={formRef}
                >
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>First Name</span>
                        <Form.Item
                          label=""
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your firstName",
                            },
                          ]}
                        >
                          <Input
                            placeholder="First name"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Last Name</span>
                        <Form.Item
                          label=""
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Last name"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Phone</span>
                        <Form.Item
                          label=""
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone",
                            },
                          ]}
                        >
                          <ReactPhoneInput
                            inputExtraProps={{
                              name: "Phone",
                              required: true,
                            }}
                            placeholder="Phone number"
                            country="in"
                            className="phone"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Email</span>
                        <Form.Item
                          label=""
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Email"
                            style={{ height: "40px", borderRadius: "5px" }}
                            type="email"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={24} xl={24} lg={12} md={24} sm={24} xs={24}>
                        <span>Where do you currently live ?</span>
                        <Form.Item
                          label=""
                          name="currentlyLive"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please input your city",
                          //   },
                          // ]}
                        >
                          <GooglePlacesAutocomplete
                            selectProps={{
                              onChange: (address) => {
                                handleSelect(address);
                              },
                            }}
                            apiKey="AIzaSyD_wscQo0hh1dFVIeB7PMfjG8pX9rDdqQY"
                            autocompletionRequest={{
                              types: ["(cities)"],
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Job Profession</span>
                        <Form.Item
                          label=""
                          name="profession"
                          rules={[
                            {
                              required: true,
                              message: "Please input your job profession",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            mode={profetions !== "" ? "" : "tags"}
                            onSelect={(title) => {
                              setProfetions(title);
                              setProfetionTitles(appConfig?.subTitle[title]);
                              formRef.current.setFieldsValue({
                                jobTitle: "",
                              });
                            }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: "8px 0",
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: "0 8px 4px",
                                  }}
                                >
                                  <Input
                                    placeholder="enter job Profession"
                                    ref={inputRef}
                                    value={name}
                                    onChange={onNameChange}
                                  />
                                  <Button
                                    style={{
                                      backgroundColor: "#10239e",
                                      color: "#fff",
                                      borderRadius: "5px",
                                    }}
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={addNewMainTitle}
                                  >
                                    Add job Profession
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {profetionMainTitles.map((item) => (
                              <Option
                                key={item}
                                value={item}
                                style={{
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              >
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Job Title</span>
                        <Form.Item
                          label=""
                          name="jobTitle"
                          rules={[
                            {
                              required: true,
                              message: "Please input your job jobTitle",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            mode={mainProfetions !== "" ? "" : "tags"}
                            onSelect={(title) => {
                              setMainProfetions(title);
                            }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: "8px 0" }} />
                                <Space style={{ padding: "0 8px 4px" }}>
                                  <Input
                                    placeholder="Enter Job Title"
                                    ref={inputRef}
                                    value={names}
                                    onChange={onNameChanges}
                                  />
                                  <Button
                                   style={{
                                    backgroundColor: "#10239e",
                                    color: "#fff",
                                    borderRadius: "5px",
                                  }}
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={addNewSubTitle}
                                  >
                                    Add Job Title
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {profetionTitles?.map((item) => (
                              <Option
                                key={item}
                                value={item}
                                style={{
                                  height: "40px",
                                }}
                              >
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Employer Name</span>
                        <Form.Item
                          label=""
                          name="employerName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your employer name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Employer name"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Facebook</span>
                        <Form.Item
                          label=""
                          name="facebook"
                          rules={[
                            {
                              required: true,
                              message: "Please input your facebook link",
                            },
                          ]}
                        >
                          <Input
                            placeholder="www.facebook.com/your profile id"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>LinkedIn</span>
                        <Form.Item
                          label=""
                          name="linkedIn"
                          rules={[
                            {
                              required: true,
                              message: "Please input your linkedIn link",
                            },
                          ]}
                        >
                          <Input
                            placeholder="www.linkedin.com/your profile id"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <span>Instagram</span>
                        <Form.Item
                          label=""
                          name="instagram"
                          rules={[
                            {
                              required: true,
                              message: "Please input your instagram link",
                            },
                          ]}
                        >
                          <Input
                            placeholder="www.instagram.com/your profile id"
                            style={{ height: "40px", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span>Did you complete your Xth standard from VVS?</span>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="didYouComplete"
                          rules={[
                            {
                              required: true,
                              message: "Please input your answer",
                            },
                          ]}
                          className="datepicker-grid"
                        >
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio value={"YES"}>YES</Radio>
                            <br />
                            <Radio value={"NO"}>NO</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      {value === "YES" ? (
                        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                          <Form.Item
                            name="selectYear"
                            rules={[
                              {
                                required: true,
                                message: "Please input your year of passing",
                              },
                            ]}
                            style={{ width: "100%" }}
                            className="datepicker-grid"
                          >
                            <DatePicker
                              placeholder="Year of passing Xth"
                              onChange={() => {}}
                              picker="year"
                              format="YYYY"
                              style={{ height: "40px", borderRadius: "5px" }}
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                        ></Col>
                      )}
                    </Row>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span>Did you complete your 12th standard from VVS?</span>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[10, 0]}>
                      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="didYouCompleteInter"
                          rules={[
                            {
                              required: true,
                              message: "Please input your answer",
                            },
                          ]}
                          className="datepicker-grid"
                        >
                          <Radio.Group
                            onChange={(e) => setValueInter(e.target.value)}
                            value={valueInter}
                          >
                            <Radio value={"YES"}>YES</Radio>
                            <br />
                            <Radio value={"NO"}>NO</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      {valueInter === "YES" ? (
                        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                          <Form.Item
                            name="selectYearInter"
                            rules={[
                              {
                                required: true,
                                message: "Please input your year of passing",
                              },
                            ]}
                            style={{ width: "100%" }}
                            className="datepicker-grid"
                          >
                            <DatePicker
                              placeholder="Year of passing 12th"
                              onChange={() => {}}
                              picker="year"
                              format="YYYY"
                              style={{ height: "40px", borderRadius: "5px" }}
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                        ></Col>
                      )}
                    </Row>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span>
                      Please indicate the start and end dates of your over all
                      schooling period at VVS
                    </span>
                    <Row gutter={[12, 0]}>
                      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Row>
                          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[10, 0]}>
                              <Col
                                xxl={24}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                              >
                                <Form.Item
                                  label=""
                                  name="startYear"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your start year",
                                    },
                                  ]}
                                  className="datepicker-grid"
                                >
                                  <DatePicker
                                    onChange={() => {}}
                                    placeholder="Start year"
                                    picker="year"
                                    format="YYYY"
                                    style={{
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                        <Row>
                          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[10, 0]}>
                              <Col
                                xxl={24}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                              >
                                <Form.Item
                                  name="endYear"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your end year",
                                    },
                                  ]}
                                  className="datepicker-grid"
                                >
                                  <DatePicker
                                    placeholder="End year"
                                    onChange={() => {}}
                                    picker="year"
                                    format="YYYY"
                                    style={{
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row>
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <span>Which House did you belong to at VVS?</span>
                        <Form.Item
                          name="wichHouse"
                          rules={[
                            {
                              required: true,
                              message: "Please Select your House",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => setValueInter(e.target.value)}
                            value={valueInter}
                          >
                            <Radio value={"Raman (Red)"}>Raman (Red)</Radio>
                            <Radio value={"Bhaba (Green)"}>Bhaba (Green)</Radio>
                            <Radio value={"Edison (Blue)"}>Edison (Blue)</Radio>
                            <Radio value={"Newton (Yellow)"}>
                              Newton (Yellow)
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item name="image">
                      <Dragger {...props} maxCount={1}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined style={{ color: "#000" }} />
                        </p>
                        <p>Upload your profile image</p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item name="emails">
                      <Checkbox
                        className="checkbox-blue"
                        onChange={() => {
                          setCheck(!check);
                        }}
                        value={"yes"}
                      >
                        Do you agree to receive emails about Alumni events?
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        style={{ backgroundColor: "#343CA4", color: "#fff" }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Col
          xl={24}
          xxl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ padding: "20px" }}
        >
          <Row>
            <Col
              xl={12}
              xxl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="footergrid-col"
            >
              <span>
                {" "}
                © Copyright {new Date().getFullYear()} Visakha Valley School
                Alumni
              </span>
            </Col>
            <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
              <div className="footer-grid">
                <span>Privacy Policy</span>
                {"|"}
                <span>Terms of Use</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
      <Loading enableLoading={loading} />
    </div>
  );
}
