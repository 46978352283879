import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Header from "./Header";
import GalleryComponent from "./GallerCommponent";

export default function Gallery() {
  useEffect(() => {
    document.title = "Visakha Valley School - Alumni Association";
  }, []);

  return (
    <div>
      <Col span={24}>
        <Col span={24}>
          <Header />
        </Col>
        <Col
          xl={24}
          xxl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ marginTop: "200px" }}
        >
          <GalleryComponent />
        </Col>
       
      </Col>
      <Col
          xl={24}
          xxl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ marginTop: "15%", padding: "20px" }}
        >
          <Row>
            <Col
              xl={12}
              xxl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="footergrid-col"
            >
              <span>
                {" "}
                © Copyright {new Date().getFullYear()} Visakha Valley School
                Alumni
              </span>
            </Col>
            <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
              <div className="footer-grid">
                <span>Privacy Policy</span>
                {"|"}
                <span>Terms of Use</span>
              </div>
            </Col>
          </Row>
        </Col>
    </div>
  );
}
