import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Checkbox,
  Collapse,
  notification,
  Avatar,
  Space,
  Divider,
  Button,
} from "antd";
import {
  SearchOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  UserOutlined,
  BarsOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { checkUser } from "../api/Apis";
import appConfig from "../config/AppConfig";
import Loading from "../Components/Loading";
import Header from "./Header";
import "../Components/Directory.css";
const { Panel } = Collapse;

const Directory = (props) => {
  const [filterData, setFilterData] = useState([]);
  const [registeredRecords, setRegisteredRecords] = useState([]);
  const [selectedCityList, setSelectedCityList] = useState([]);
  const [selectedEndYearList, setSelectedEndYearList] = useState([]);
  const [selectedStartYearList, setSelectedStartYearList] = useState([]);
  const [selectedJobList, setSelectedJobList] = useState([]);
  const [searchCityList, setSearchCityList] = useState("");
  const [searchEndYearList, setSearchEndYearList] = useState("");
  const [searchStartYearList, setSearchStartYearList] = useState("");
  const [searchJobList, setSearchJobList] = useState("");
  const [searchProfile, setSearchProfile] = useState("");
  const [enableCardView, setEnableCardView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [job, setJob] = useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  useEffect(() => {
    document.title = "Visakha Valley School - Alumni Association";
    registerData();
    getMasterList();
  }, []);

  const registerData = () => {
    setLoading(true);
    try {
      checkUser(
        appConfig["entityNames"]["registrations"] + "?isActive=true"
      ).then((result) => {
        setLoading(false);
        if (result.success) {
          setRegisteredRecords(result.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };

  const getMasterList = () => {
    try {
      checkUser(appConfig["entityNames"]["getMasterList"]).then((result) => {
        if (result.success) {
          setFilterData(result?.data);
        } else {
          notification["error"]({
            message: "Something went wrong!",
          });
        }
      });
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Something went wrong!",
      });
    }
  };
  const handleClick = () => setToggle(!toggle);
  const handleClickjob = () => setJob(!job);
  const handleClickstart = () => setStart(!start);
  const handleClickEnd = () => setEnd(!end);

  const onChangeCity = (newCity) => {
    let existingCityList = selectedCityList;
    if (existingCityList.includes(newCity)) {
      let findIndex = existingCityList.indexOf(newCity);
      existingCityList.splice(findIndex, 1);
      setSelectedCityList([...existingCityList]);
    } else {
      setSelectedCityList([...selectedCityList, newCity]);
    }
  };

  const onChangeJob = (newJob) => {
    let existingJobList = selectedJobList;
    if (existingJobList.includes(newJob)) {
      let findIndex = existingJobList.indexOf(newJob);
      existingJobList.splice(findIndex, 1);
      setSelectedJobList([...existingJobList]);
    } else {
      setSelectedJobList([...selectedJobList, newJob]);
    }
  };

  const onChangeStartYear = (newStartYear) => {
    let existingStartYearList = selectedStartYearList;
    if (existingStartYearList.includes(newStartYear)) {
      let findIndex = existingStartYearList.indexOf(newStartYear);
      existingStartYearList.splice(findIndex, 1);
      setSelectedStartYearList([...existingStartYearList]);
    } else {
      setSelectedStartYearList([...selectedStartYearList, newStartYear]);
    }
  };

  const onChangeEndYear = (newEndYear) => {
    let existingEndYearList = selectedEndYearList;
    if (existingEndYearList.includes(newEndYear)) {
      let findIndex = existingEndYearList.indexOf(newEndYear);
      existingEndYearList.splice(findIndex, 1);
      setSelectedEndYearList([...existingEndYearList]);
    } else {
      setSelectedEndYearList([...selectedEndYearList, newEndYear]);
    }
  };

  const getDataList = (searchItem, dataList) => {
    let itemsList = [];

    itemsList =
      searchItem === ""
        ? dataList
        : dataList?.filter((item) =>
            item?.toLowerCase()?.includes(searchItem?.toLowerCase())
          );

    console.log("itemsList", itemsList);

    return itemsList;
  };

  const clearFilters = () => {
    setSearchProfile("");
    setSelectedCityList([]);
    setSelectedJobList([]);
    setSelectedEndYearList([]);
    setSelectedStartYearList([]);
    setSearchCityList("");
    setSearchEndYearList("");
    setSearchStartYearList("");
    setSearchJobList("");
  };

  const getFilteredData = () => {
    let totalRecords = registeredRecords;

    if (selectedCityList.length > 0) {
      totalRecords = totalRecords.filter((person) =>
        selectedCityList.includes(person?.currentlyLive)
      );
    }

    if (selectedJobList.length > 0) {
      totalRecords = totalRecords.filter((person) =>
        selectedJobList.includes(person?.profession)
      );
    }

    if (selectedStartYearList.length > 0) {
      totalRecords = totalRecords.filter((person) =>
        selectedStartYearList.includes(person?.startYear)
      );
    }

    if (selectedEndYearList.length > 0) {
      totalRecords = totalRecords.filter((person) =>
        selectedEndYearList.includes(person?.endYear)
      );
    }

    if (searchProfile !== "") {
      totalRecords = totalRecords.filter(
        (person) =>
          person?.firstName
            ?.toLowerCase()
            .indexOf(searchProfile?.toLowerCase()) > -1 ||
          person?.lastName
            ?.toLowerCase()
            .indexOf(searchProfile?.toLowerCase()) > -1 ||
          person?.profession
            ?.toLowerCase()
            .indexOf(searchProfile?.toLowerCase()) > -1 ||
          person?.currentlyLive
            ?.toLowerCase()
            .indexOf(searchProfile?.toLowerCase()) > -1 ||
          person?.startYear
            ?.toLowerCase()
            .indexOf(searchProfile?.toLowerCase()) > -1 ||
          person?.endYear?.toLowerCase().indexOf(searchProfile?.toLowerCase()) >
            -1
      );
    }

    return totalRecords;
  };
  const genExtra = () => (
    <Button
      onClick={(event) => {
        handleClick(!toggle);
        event.stopPropagation();
      }}
      style={{border:"0px"}}
    >
      <SearchOutlined style={{fontSize:"20px"}}/>
    </Button>
  );
  const genExtrajob = () => (
  <Button  style={{border:"0px"}}>
<SearchOutlined
      onClick={(event) => {
        handleClickjob(!job);
        event.stopPropagation();
      }}
      style={{fontSize:"20px"}}
    />
  </Button>  
  );
  const genExtraStart = () => (
   <Button  style={{border:"0px"}}>
<SearchOutlined
      onClick={(event) => {
        handleClickstart(!job);
        event.stopPropagation();
      }}
      style={{fontSize:"20px"}}
    />
   </Button> 
  );
  const genExtraEnd = () => (
   <Button  style={{border:"0px"}}>
     <SearchOutlined
      onClick={(event) => {
        handleClickEnd(!job);
        event.stopPropagation();
      }}
      style={{fontSize:"20px"}}
    />
   </Button>
  );
  const ListView = () => {
    return (
      <Row gutter={[12, 0]}>
        {getFilteredData().map((f, index) => (
          <Col xxl={24} xl={24} lg={24} md={8} sm={12} xs={24} key={index}>
            <Card
              style={{
                boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.2)",
                padding: "1rem",
                marginTop: "20px",
              }}
            >
              <Row>
                <Col
                  xxl={2}
                  xl={4}
                  lg={4}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "center" }}
                  className="avatar-grid"
                >
                  <Space wrap size={16}>
                    {f?.image !== null ? (
                      <Avatar
                        size={84}
                        icon={
                          <img src={f?.imageUrl} style={{ width: "100%" }} />
                        }
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#fafafa",
                          verticalAlign: "middle",
                          color: "#061178",
                        }}
                        size={84}
                      >
                        <h1
                          style={{
                            fontSize: "48px",
                            marginTop: "-7px",
                            textTransform: "uppercase",
                          }}
                        >
                          {f?.firstName?.charAt(0)}
                        </h1>
                      </Avatar>
                    )}
                  </Space>
                </Col>
                <Col xxl={22} xl={20} lg={20} md={24} sm={24} xs={24}>
                  <Row>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="events-gridsct"
                    >
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <span style={{ color: "#061178" }}>
                          Alumni: {f?.endYear}
                        </span>
                      </Col>
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <b
                          style={{
                            textTransform: "capitalize",
                            fontSize: "16px",
                          }}
                        >
                          {f?.firstName} {f?.lastName}
                        </b>
                      </Col>
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <span style={{ textTransform: "capitalize" }}>
                          {f?.profession}
                        </span>
                      </Col>
                      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <span
                          style={{
                            color: "#B0B0B0",
                            textTransform: "capitalize",
                          }}
                        >
                          <EnvironmentOutlined /> {f?.currentlyLive}
                        </span>
                      </Col>
                    </Col>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="socialmedia-directorybox"
                    >
                      <Row
                        justify={"end"}
                        gutter={[16, 16]}
                        style={{ marginTop: "30px" }}
                      >
                        {f?.facebook !== "" &&
                          f?.facebook !== null &&
                          f?.facebook !== undefined && (
                            <Col>
                              <a
                                href={
                                  "https://" +
                                  f?.facebook.replace("https://", "")
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FacebookOutlined
                                  style={{
                                    color: appConfig.app_colors.greyLight,
                                    fontSize: "24px",
                                  }}
                                />
                              </a>
                            </Col>
                          )}

                        {f?.instagram !== "" &&
                          f?.instagram !== null &&
                          f?.instagram !== undefined && (
                            <Col>
                              <a
                                href={
                                  "https://" +
                                  f?.instagram.replace("https://", "")
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <InstagramOutlined
                                  style={{
                                    color: appConfig.app_colors.greyLight,
                                    fontSize: "24px",
                                  }}
                                />
                              </a>
                            </Col>
                          )}

                        {f?.linkedIn !== "" &&
                          f?.linkedIn !== null &&
                          f?.linkedIn !== undefined && (
                            <Col>
                              <a
                                href={
                                  "https://" +
                                  f?.linkedIn.replace("https://", "")
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LinkedinOutlined
                                  style={{
                                    color: appConfig.app_colors.greyLight,
                                    fontSize: "24px",
                                  }}
                                />
                              </a>
                            </Col>
                          )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const CardView = () => {
    return (
      <>
        <Col
          xxl={24}
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ textAlign: "center" }}
        >
          <Row gutter={[16, 16]}>
            {getFilteredData().map((f, index) => (
              <Col xxl={4} xl={6} lg={8} md={8} sm={12} xs={24} key={index}>
                <Card
                  hoverable
                  style={{
                    boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span style={{ color: "#061178" }}>
                      Alumni {f?.endYear}
                    </span>
                  </Col>
                  <Col
                    xxl={24}
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ margin: "15px 0px" }}
                  >
                    <Space wrap size={16}>
                      {f?.image !== null ? (
                        <Avatar
                          size={84}
                          icon={
                            <img
                              src={f?.imageUrl}
                              style={{ width: "100%", height: "100%" }}
                            />
                          }
                        />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: "#fafafa",
                            verticalAlign: "middle",
                            color: "#061178",
                          }}
                          size={84}
                        >
                          <h1
                            style={{
                              fontSize: "48px",
                              marginTop: "-7px",
                              textTransform: "uppercase",
                            }}
                          >
                            {f?.firstName?.charAt(0)}
                          </h1>
                        </Avatar>
                      )}
                    </Space>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <b style={{ textTransform: "capitalize" }}>
                      {f?.firstName} {f?.lastName}
                    </b>
                  </Col>

                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span style={{ textTransform: "capitalize" }}>
                      {f?.profession}
                    </span>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <span
                      style={{
                        color: "#B0B0B0",
                        textTransform: "capitalize",
                      }}
                    >
                      <EnvironmentOutlined /> {f?.currentlyLive}
                    </span>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row
                      justify={"center"}
                      gutter={[16, 16]}
                      style={{ marginTop: "15px" }}
                    >
                      {f?.facebook !== "" &&
                      f?.facebook !== null &&
                      f?.facebook !== undefined ? (
                        <Col>
                          <a
                            href={
                              "https://" + f?.facebook.replace("https://", "")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookOutlined
                              style={{
                                color: appConfig.app_colors.greyLight,
                                fontSize: "24px",
                              }}
                            />
                          </a>
                        </Col>
                      ) : (
                        <Col style={{ height: "24px" }}></Col>
                      )}

                      {f?.instagram !== "" &&
                      f?.instagram !== null &&
                      f?.instagram !== undefined ? (
                        <Col>
                          <a
                            href={
                              "https://" + f?.instagram.replace("https://", "")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstagramOutlined
                              style={{
                                color: appConfig.app_colors.greyLight,
                                fontSize: "24px",
                              }}
                            />
                          </a>
                        </Col>
                      ) : (
                        <Col style={{ height: "24px" }}></Col>
                      )}

                      {f?.linkedIn !== "" &&
                      f?.linkedIn !== null &&
                      f?.linkedIn !== undefined ? (
                        <Col>
                          <a
                            href={
                              "https://" + f?.linkedIn.replace("https://", "")
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedinOutlined
                              style={{
                                color: appConfig.app_colors.greyLight,
                                fontSize: "24px",
                              }}
                            />
                          </a>
                        </Col>
                      ) : (
                        <Col style={{ height: "24px" }}></Col>
                      )}
                    </Row>
                  </Col>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </>
    );
  };

  return (
    <div>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Header />
      </Col>
      <Col
        xxl={24}
        xl={24}
        lg={24}
        md={24}
        sm={24}
        xs={24}
        className="search"
        style={{
          marginTop: "86px",
          position: "fixed",
          zIndex: 100,
          width: "100%",
        }}
      >
        <Row>
          <Col span={22} offset={1}>
            <Input
              placeholder="Name, job title, location..."
              suffix={<SearchOutlined style={{ fontSize: "25px" }} />}
              style={{ marginTop: "10px", borderRadius: "5px", height: "40px" }}
              onChange={(e) => setSearchProfile(e.target.value)}
            />
          </Col>
        </Row>
      </Col>
      <Col
        xxl={24}
        xl={24}
        lg={24}
        md={24}
        sm={24}
        xs={24}
        style={{ marginTop: "180px" }}
      >
        <div className="directorygrid">
          <Row gutter={[16, 0]}>
            <Col
              xxl={4}
              xl={6}
              lg={6}
              md={24}
              sm={24}
              xs={24}
              style={{ marginBottom: "20px" }}
            >
              <Card
                style={{
                  boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                }}
              >
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <b>Filter</b>
                  </Col>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "end" }}
                  >
                    <div
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      <a style={{ color: "#061178" }}>Clear filter</a>
                    </div>
                  </Col>
                </Row>

                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  // expandIcon={({ isActive }) => (
                  //   <CaretRightOutlined rotate={isActive ? 90 : 0} style={{textAlign:"end"}}/>
                  // )}
                  style={{ marginTop: "30px", backgroundColor: "#fff" }}
                >
                  <Panel
                    header="City"
                    key="1"
                    style={{ border: "none" }}
                    extra={genExtra()}
                  >
                    <Col span={2}></Col>
                    <Col span={2}></Col>
                    {toggle && (
                      <Input
                        placeholder="Search City"
                        onChange={(e) => setSearchCityList(e.target.value)}
                      />
                    )}

                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="container1"
                    >
                      {filterData?.currentlyLive &&
                        getDataList(
                          searchCityList,
                          filterData?.currentlyLive
                        ).map((liveCity, index) => (
                          <Row key={index} className="fakeDiv">
                            <Checkbox
                              className="checkbox-blue"
                              value={liveCity}
                              checked={selectedCityList.includes(liveCity)}
                              onChange={() => onChangeCity(liveCity)}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Col
                                xxl={24}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                              >
                                <Row>
                                  <Col span={20}>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {liveCity}
                                    </span>{" "}
                                  </Col>
                                </Row>
                              </Col>
                            </Checkbox>
                          </Row>
                        ))}
                    </Col>
                  </Panel>
                  <Panel
                    header="Job Profession"
                    key="2"
                    style={{ border: "none" }}
                    extra={genExtrajob()}
                  >
                    {job && (
                      <Input
                        placeholder="Search Profession"
                        onChange={(e) => setSearchJobList(e.target.value)}
                      />
                    )}

                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="container1"
                    >
                      {appConfig?.profetion &&
                        getDataList(searchJobList, appConfig?.profetion).map(
                          (job, index) => (
                            <Row key={index} className="fakeDiv">
                              <Checkbox
                                className="checkbox-blue"
                                value={job}
                                checked={selectedJobList.includes(job)}
                                onChange={() => onChangeJob(job)}
                                style={{ textTransform: "capitalize" }}
                              >
                                {job}
                              </Checkbox>
                            </Row>
                          )
                        )}
                    </Col>
                  </Panel>

                  <Panel
                    header="Start Year"
                    key="3"
                    style={{ border: "none" }}
                    extra={genExtraStart()}
                  >
                    {start && (
                      <Input
                        placeholder="Search Start Year"
                        onChange={(e) => setSearchStartYearList(e.target.value)}
                      />
                    )}

                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="container1"
                    >
                      {filterData?.startYear &&
                        getDataList(
                          searchStartYearList,
                          filterData?.startYear
                        ).map((startyear, index) => (
                          <Row key={index} className="fakeDiv">
                            <Checkbox
                              className="checkbox-blue"
                              value={startyear}
                              checked={selectedStartYearList.includes(
                                startyear
                              )}
                              onChange={() => onChangeStartYear(startyear)}
                              style={{ textTransform: "capitalize" }}
                            >
                              {startyear}
                            </Checkbox>
                          </Row>
                        ))}
                    </Col>
                  </Panel>

                  <Panel
                    header="End Year"
                    key="4"
                    style={{ border: "none" }}
                    extra={genExtraEnd()}
                  >
                    {end && (
                      <Input
                        placeholder="Search End Year"
                        onChange={(e) => setSearchEndYearList(e.target.value)}
                      />
                    )}

                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="container1"
                    >
                      {filterData?.endYear &&
                        getDataList(searchEndYearList, filterData?.endYear).map(
                          (endYear, index) => (
                            <Row key={index} className="fakeDiv">
                              <Checkbox
                                className="checkbox-blue"
                                value={endYear}
                                checked={selectedEndYearList.includes(endYear)}
                                onChange={() => onChangeEndYear(endYear)}
                                style={{ textTransform: "capitalize" }}
                              >
                                {endYear}
                              </Checkbox>
                            </Row>
                          )
                        )}
                    </Col>
                  </Panel>
                </Collapse>
              </Card>
            </Col>

            <Col
              xxl={20}
              xl={18}
              lg={18}
              md={24}
              sm={24}
              xs={24}
              style={{ marginBottom: "20px" }}
            >
              <Row
                justify={"space-between"}
                style={{ padding: "0px 15px", marginBottom: "15px" }}
              >
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                  <b style={{ fontSize: "16px" }}>
                    Showing {getFilteredData().length} results
                  </b>
                </Col>

                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  className="grid-listedview"
                >
                  <Row gutter={[8, 8]} justify="end">
                    <Col>
                      <BarsOutlined
                        style={{
                          color: !enableCardView
                            ? appConfig.app_colors.blueDark
                            : appConfig.app_colors.greyLight,
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          setEnableCardView(false);
                        }}
                      />
                    </Col>
                    <Divider
                      className="dividerVertical1"
                      type="vertical"
                      style={{ height: "25px" }}
                    />
                    <Col>
                      <AppstoreOutlined
                        style={{
                          color: enableCardView
                            ? appConfig.app_colors.blueDark
                            : appConfig.app_colors.greyLight,
                          fontSize: "24px",
                        }}
                        onClick={() => {
                          setEnableCardView(true);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                {enableCardView ? CardView() : ListView()}
              </Col>
            </Col>
          </Row>
        </div>
        <Col
          xl={24}
          xxl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ padding: "20px" }}
        >
          <Row>
            <Col
              xl={12}
              xxl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="footergrid-col"
            >
              <span>
                {" "}
                © Copyright {new Date().getFullYear()} Visakha Valley School
                Alumni
              </span>
            </Col>
            <Col xl={12} xxl={12} lg={12} md={12} sm={24} xs={24}>
              <div className="footer-grid">
                <span>Privacy Policy</span>
                {"|"}
                <span>Terms of Use</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
      <Loading enableLoading={loading} />
    </div>
  );
};

export default Directory;
